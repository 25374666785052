import React, { Component } from "react";
import { Alert, Image, ImageBackground, Text, TouchableOpacity, StyleSheet, View, TextInput, Platform, StatusBar } from "react-native";
import Icon from 'react-native-vector-icons/FontAwesome';

import axios from 'axios';

import helpers from '../utils/Helper';
import Color from '../values/Color';
import Constant from '../values/Constant';
import Data from "../utils/Data";

import i18n from 'i18n-js';

const styles = StyleSheet.create({
	image: {
		width: '100%',
		height: '100%',
		flex: 1,
		flexDirection: "column",
		alignItems: 'center'
	},
	logo: {
		width: '60%',
		height: '30%',
		resizeMode: 'contain'
	},
	searchSection: {
		height: 40,
		width: "90%",
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 20,
		borderColor: Color.SEMI_TRANSPARENT,
		borderWidth: 2,
		borderRadius: 10,
		backgroundColor: Color.SEMI_TRANSPARENT,
		color: Color.WHITE
	},
	icon: {
		paddingLeft: 12
	},
	textInput: {
		height: 40,
		flex: 9,
		padding: 10,
		fontSize: 14,
		color: Color.WHITE
	},
	loginBtn: {
		width: "90%",
		backgroundColor: Color.WHITE,
		flexDirection: 'row',
		justifyContent: 'center',
		padding: 12,
		borderRadius: 10,
		marginBottom: 10,
	},
	forgotPW: {
		color: Color.WHITE,
		marginRight: 'auto',
		marginLeft: '5%',
		textDecorationLine: 'underline'
	},
	orText: {
		color: Color.WHITE,
		marginBottom: 15
	},
	registerBtn: {
		width: "90%",
		backgroundColor: Color.TRANSPARENT,
		flexDirection: 'row',
		justifyContent: 'center',
		padding: 12,
		borderRadius: 10,
		borderWidth: 2,
		borderColor: Color.WHITE
	},
	copyrightText: {
		position: 'absolute',
		bottom: 15
	},
	langBtn: {
		position: 'absolute',
		top: 15,
		right: 15,
		backgroundColor: Color.SEMI_TRANSPARENT,
		paddingHorizontal: 8,
		paddingVertical: 15,
		borderRadius: 10,
		color: Color.WHITE
	},
});

export default class LoginScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			phone: "",
			password: "",
			year: new Date().getFullYear(),
			i18n: null
		};
	}

	async componentDidMount() {
		// Go to homepage immediately if the token exists.
        this.focusListener = this.props.navigation.addListener("focus", async () => {
			let token = await helpers.getToken();
            if (token) this.getCheckinTypes();
        });

		await helpers.getI18nConfigs(i18n);
		this.setState({ i18n: i18n });
	}

	async login() {
		var user = new Object();
		user.phone_no = this.state.phone;
		user.password = this.state.password;

		if (!user.phone_no || !user.password) {
			alert(this.state.i18n?.t('enter_user_info'));
			return;
		}

		this.setState({
			fromFetch: false,
			loading: true,
		})
        const config = await helpers.getLangHeaderOnly();
		axios.post(Constant.API_URL + Constant.API_PATH + "/staff/login", user, config)
			.then(response => {
				this.setState({
					loading: false,
					axiosData: response.data
				})

				if (response.data.data.token) {
					// Save the token
					helpers.setToken(response.data.data.token);

					this.getCheckinTypes();
				} else {
					alert(response.data.message);
				}
			})
			.catch(error => {
				helpers.showAPIError(error);
			});
	}

	async getCheckinTypes() {
		const config = await helpers.getRequestHeader();

		this.setState({
			fromFetch: false,
			loading: true,
		})
		axios.get(Constant.API_URL + Constant.API_PATH + "/company/settings", config)
			.then(response => {
				this.setState({
					loading: false,
					axiosData: response.data
				})

				let types = response.data.data.checkInTypes;
				if (types) {
					var list = [];
					types.forEach((item, index) => {
						var obj = new Object();
						obj['id'] = item.code;
						obj['title'] = item.description;
						obj['selected'] = false;
						list.push(obj);
					});

					Data.checkinTypes = list;

					// Change screen
					this.goToHomepage();
				} else {
					alert(response.data.message);
				}
			})
			.catch(error => {
				helpers.showAPIError(error);
			});
	}

	goToHomepage() {
		this.props.navigation.navigate(Constant.SCREEN_HOME);
		this.setState({ phone: "", password: "" });
	}

	render() {
		return (
			<ImageBackground source={require('../assets/img/gradient_background.png')}
				resizeMode="cover" style={styles.image}>
				<StatusBar barStyle="light-content" backgroundColor={Color.PEACH} />

				<TouchableOpacity style={styles.langBtn}
					onPress={() => {
						helpers.checkNetworkBeforeAction(() => {
							this.props.navigation.navigate(Constant.SCREEN_LANG);
						})
					}}>
					<Text style={{ color: Color.WHITE }}>
						{this.state.i18n?.t('lang_alias')}
					</Text>
				</TouchableOpacity>

				<Image
					style={styles.logo}
					source={require('../assets/img/staffenter_logo.png')}
				/>

				<View style={styles.searchSection}>
					<Icon style={styles.icon} name="phone" size={25} color={Color.WHITE} />
					<TextInput
						style={styles.textInput}
						placeholder={this.state.i18n?.t('phone_num')}
						placeholderTextColor={Color.WHITE}
						onChangeText={(phone) => this.setState({ phone })}
						value={this.state.phone}
						underlineColorAndroid="transparent"
						keyboardType="phone-pad"
					/>
				</View>

				<View style={styles.searchSection}>
					<Icon style={styles.icon} name="lock" size={25} color={Color.WHITE} />
					<TextInput
						style={styles.textInput}
						placeholder={this.state.i18n?.t('password')}
						placeholderTextColor={Color.WHITE}
						onChangeText={(password) => this.setState({ password })}
						value={this.state.password}
						underlineColorAndroid="transparent"
						secureTextEntry={true}
					/>
				</View>

				<TouchableOpacity
					style={styles.loginBtn}
					onPress={() => {
						helpers.checkNetworkBeforeAction(() => {
							this.login();
						})
					}}
				>
					<Text style={{ color: Color.PEACH }}>{this.state.i18n?.t("login")}</Text>
				</TouchableOpacity>

				<Text style={styles.forgotPW}
					onPress={() => {
						helpers.checkNetworkBeforeAction(() => {
							this.props.navigation.navigate(Constant.SCREEN_FORGOT_PW);
						})
					}}>
					{this.state.i18n?.t('forgot_password')}
				</Text>

				<Text style={styles.orText}>{this.state.i18n?.t('or')}</Text>

				<TouchableOpacity
					style={styles.registerBtn}
					onPress={() => {
						helpers.checkNetworkBeforeAction(() => {
							this.props.navigation.navigate(Constant.SCREEN_REGISTER);
						})
					}}
				>
					<Text style={{ color: Color.WHITE }}>{this.state.i18n?.t('sign_up')}</Text>
				</TouchableOpacity>

				<Text style={styles.copyrightText}>© {this.state.year} People Tech Inc.</Text>
			</ImageBackground>
		);
	}
}