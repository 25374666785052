import Constants from 'expo-constants';


const Constant = {
    // API endpoints
    'API_URL': Constants.manifest?.extra?.apiEndpoint,
    // 'API_URL': 'https://peopletech-api-prod.staffenter.com',
    'API_PATH': "/api/v1/app",

    // Contact us,
    'CS_EMAIL': 'cs@staffenter.hk',

    // Time constants,
    'HALF_SECOND': 500,
    'SECOND': 1000,

    // Web links,
    'TNC_URL': 'https://peopletech.hk/staffenter/terms_and_conditions',
    'PRIVACY_POLICY_URL': 'https://peopletech.hk/staffenter/privacy_policy',
    'VACCINE_RECORD_LINK': Constants.manifest?.extra?.vaccineRecordLink,

    // Platforms
    'AOS': "android",
    'IOS': "ios",

    // Assets
    'IMAGE_PATH': "../assets/img/",
    'BASE64_PREFIX': "data:image/jpeg;base64,",

    // Key
    'API_TOKEN': "secure_token",
    'REG_PHOTO': "register_photo",
    'CHK_IN_PHOTO': "check_in_photo",
    'LANG': "key_lang",

    // Screen names
    'SCREEN_LOGIN': "Login",
    'SCREEN_REGISTER': "Register",
    'SCREEN_PHOTO': "Photo Taking",
    'SCREEN_HOME': "Home",
    'SCREEN_PROFILE': "Profile",
    'SCREEN_QR': "QR Code",
    'SCREEN_STEP_1': "Step 1",
    'SCREEN_STEP_2': "Step 2",
    'SCREEN_STEP_3': "Step 3",
    'SCREEN_STEP_4': "Step 4",
    'SCREEN_WEB': "Static Web",
    'SCREEN_FORGOT_PW': "Forgot Pw",
    'SCREEN_LANG': "Language",
    'SCREEN_SETTINGS': "Settings",
    'SCREEN_VACCINE': "Vaccine Record",
}

// console.log(process.env);

export default Constant;