import * as React from 'react';
import { WebView } from 'react-native-webview';
import { StyleSheet, Platform } from 'react-native';

import Constant from '../values/Constant';

export default function WebViewScreen(props) {
    const content = props?.route?.params?.content ? props.route.params.content : undefined;
    const link = props?.route?.params?.link ? props.route.params.link : Constant.TNC_URL;

    return (
        (content != null && content != undefined && content != "" ?
            // Static content
            (Platform.OS === 'web' ?
                <iframe srcDoc={content} height="100%" width="100%" /> :

                <WebView originWhitelist={['*']}
                    source={content}
                    style={styles.container}
                    startInLoadingState={true}
                    useWebKit={true}
                />
            )

            :

            // Url
            (Platform.OS === 'web' ?
                <iframe src={link} height="100%" width="100%" /> :

                <WebView originWhitelist={['*']}
                    source={{ uri: link, baseUrl: '' }}
                    style={styles.container}
                    startInLoadingState={true}
                    useWebKit={true}
                />
            )
        )
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        width: '100%'
    },
});