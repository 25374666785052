const zh_hans = {
    lang_alias: '简',
    lang_full: '简体中文',
    no_network_access: '没有网络连接。',
    back: '返回',
    login: '登入',
    phone_num: '电话号码',
    password: '密码',
    enter_user_info: '请先输入用户资料。',
    forgot_password: '忘记密码?',
    sign_up: '注册',
    or: '或',

    //
    fill_in_required_info: '请填写所有必填的资料。',
    passwords_not_match: '密码不相符。',
    invalid_hkid: '请输入有效的香港身分证号码。',
    registered_successfully: '注册成功。',
    register_reminder: '输入所需的资料并按继续。',
    upload_photo: '上载照片',
    phone_number_reg: '电话号码*',
    email: '电邮',
    hkid_number: '香港身分证号码 例子: A123456',
    surname_last_name: '姓氏 *',
    other_name_first_name: '名字 *',
    confirm_password: '确认密码',
    terms_and_conditions_reg: '通过建立帐户，您将自动接受所有条款及细则。',

    //
    update_pw_successfully: '成功更新密码。',
    new_password: '新密码',
    submit: '提交',

    //
    today: '今天',
    check_in_now: '馬上打卡',
    check_in_records: '打卡记录',
    vaccine_record: '疫苗记录',
    my_profile: '我的个人资料',
    tnc: '条款及细则',
    privacy_policy: '隐私政策',
    settings: '設定',
    please_input_staff_id: '请输入在 %s 的员工编号',
    remarks: '备注',

    // 
    info: '资料',
    first_name: '名字',
    last_name: '姓氏',
    duties: '职责',
    logout: '登出',

    // 
    language: '语言',
    contact_us: '联络我们',
    version_num: '版本号',

    // 
    obtaining_location: '正在获取您的位置，请稍后再试。',
    no_camera_access: '没有存取相机的权限。',
    no_gps_access: '没有存取位置的权限。',
    scan_qr_code_to_check_in: '扫描二维码来打卡。',

    // 
    step_1: '步骤1. 请自拍',
    retake_photo: '重拍',
    next: '下一步',

    // 
    unknown_issue_raise: '发生不明错误。',
    take_photo_problem: '拍照期间发生错误，请稍后再试。',
    no_access_camera: '无法存取相机',
    take_picture: '拍照',
    flip: '反转',
    camera_type_not_available: '你的装置不支援其他镜头方向。',
    please_wait_for_flipping: '镜头反转中，请耐心等候。',

    // 
    step_2: '步骤2. 选择打卡类型',
    please_select_check_in_type: '请选择打卡类型。',

    // 
    step_3: '步骤3. 确认资料',
    type: '类型',
    date: '日期',
    check_in: '打卡',

    //
    check_in_successful: '打卡成功',
    back_to_homepage: '返回主頁',

    // 
    weekday: {
        sun: '周日',
        mon: '周一',
        tue: '周二',
        wed: '周三',
        thu: '周四',
        fri: '周五',
        sat: '周六'
    },

    // 
    month: {
        jan: '1月',
        feb: '2月',
        mar: '3月',
        apr: '4月',
        may: '5月',
        jun: '6月',
        jul: '7月',
        aug: '8月',
        sep: '9月',
        oct: '10月',
        nov: '11月',
        dec: '12月'
    },
};

const zh_hant = {
    lang_alias: '繁',
    lang_full: '繁體中文',
    no_network_access: '沒有網絡連接。',
    back: '返回',
    login: '登入',
    phone_num: '電話號碼',
    password: '密碼',
    enter_user_info: '請先輸入用戶資料。',
    forgot_password: '忘記密碼?',
    sign_up: '註冊',
    or: '或',

    //
    fill_in_required_info: '請填寫所有必填的資料。',
    passwords_not_match: '密碼不相符。',
    invalid_hkid: '請輸入有效的香港身分證號碼。',
    registered_successfully: '註冊成功。',
    register_reminder: '輸入所需的資料並按繼續。',
    upload_photo: '上載照片',
    phone_number_reg: '電話號碼*',
    email: '電郵',
    hkid_number: '香港身分證號碼 例子: A123456',
    surname_last_name: '姓氏 *',
    other_name_first_name: '名字 *',
    confirm_password: '確認密碼',
    terms_and_conditions_reg: '通過建立帳戶，您將自動接受所有條款及細則。',

    //
    update_pw_successfully: '成功更新密碼。',
    new_password: '新密碼',
    submit: '提交',

    //
    today: '今天',
    check_in_now: '馬上打卡',
    check_in_records: '打卡記錄',
    vaccine_record: '疫苗記錄',
    my_profile: '我的個人資料',
    tnc: '條款及細則',
    privacy_policy: '隱私政策',
    settings: '設定',
    please_input_staff_id: '請輸入在 %s 的員工編號',
    remarks: '備註',

    // 
    info: '資料',
    first_name: '名字',
    last_name: '姓氏',
    duties: '職責',
    logout: '登出',

    // 
    language: '語言',
    contact_us: '聯絡我們',
    version_num: '版本號',

    // 
    obtaining_location: '正在獲取您的位置，請稍後再試。',
    no_camera_access: '沒有存取相機的權限。',
    no_gps_access: '沒有存取位置的權限。',
    scan_qr_code_to_check_in: '掃描二維碼來打卡。',

    // 
    step_1: '步驟1. 請自拍',
    retake_photo: '重拍',
    next: '下一步',

    // 
    unknown_issue_raise: '發生不明錯誤。',
    take_photo_problem: '拍照期間發生錯誤，請稍後再試。',
    no_access_camera: '無法存取相機',
    take_picture: '拍照',
    flip: '反轉',
    camera_type_not_available: '你的裝置不支援其他鏡頭方向。',
    please_wait_for_flipping: '鏡頭反轉中，請耐心等候。',

    // 
    step_2: '步驟2. 選擇打卡類型',
    please_select_check_in_type: '請選擇打卡類型。',

    // 
    step_3: '步驟3. 確認資料',
    type: '類型',
    date: '日期',
    check_in: '打卡',

    //
    check_in_successful: '打卡成功',
    back_to_homepage: '返回主頁',

    // 
    weekday: {
        sun: '週日',
        mon: '週一',
        tue: '週二',
        wed: '週三',
        thu: '週四',
        fri: '週五',
        sat: '週六'
    },

    // 
    month: {
        jan: '1月',
        feb: '2月',
        mar: '3月',
        apr: '4月',
        may: '5月',
        jun: '6月',
        jul: '7月',
        aug: '8月',
        sep: '9月',
        oct: '10月',
        nov: '11月',
        dec: '12月'
    },
};

const en = {
    lang_alias: 'ENG',
    lang_full: 'English',
    no_network_access: 'No network access.',
    back: 'Back',
    login: 'Login',
    phone_num: 'Phone Number',
    password: 'Password',
    enter_user_info: 'Please enter the user credentials.',
    forgot_password: 'Forgot Password?',
    sign_up: 'Sign Up',
    or: 'or',

    //
    fill_in_required_info: 'Please fill in all required information.',
    passwords_not_match: 'Passwords are not match.',
    invalid_hkid: 'Please enter a correct HKID number.',
    registered_successfully: 'Registered Successfully.',
    register_reminder: 'Fill in the required details and Click Proceed.',
    upload_photo: 'Upload Photo',
    phone_number_reg: 'Phone Number*',
    email: 'Email',
    hkid_number: 'HKID Number e.g. A123456',
    surname_last_name: 'Surname / Last name *',
    other_name_first_name: 'Other name / First name *',
    confirm_password: 'Confirm Password',
    terms_and_conditions_reg: 'By Creating Account, you are automatically accepting all the Terms and Conditions.',

    //
    update_pw_successfully: 'Update Password Successfully.',
    new_password: 'New Password',
    submit: 'Submit',

    //
    today: 'Today',
    check_in_now: 'Check-in Now',
    check_in_records: 'Check-in Records',
    vaccine_record: 'Vaccine Record',
    my_profile: 'My Profile',
    tnc: 'Terms and Conditions',
    privacy_policy: 'Privacy Policy',
    settings: 'Settings',
    please_input_staff_id: 'Please input staff ID for %s',
    remarks: 'Remarks',

    // 
    info: 'Info',
    first_name: 'First Name',
    last_name: 'Last Name',
    duties: 'Duties',
    logout: 'Logout',

    // 
    language: 'Language',
    contact_us: 'Contact Us',
    version_num: 'Version Number',

    // 
    obtaining_location: 'Your location is being obtained, please try again later.',
    no_camera_access: 'No permission to access to camera.',
    no_gps_access: 'No permission to access to GPS.',
    scan_qr_code_to_check_in: 'Scan the QR code to check in.',

    // 
    step_1: 'Step 1. Take a picture of yourself',
    retake_photo: 'Retake Photo',
    next: 'Next',

    // 
    unknown_issue_raise: 'An unknown issue raised.',
    take_photo_problem: 'A problem occurred while taking photo, please try again.',
    no_access_camera: 'No access to camera',
    take_picture: 'Take Picture',
    flip: 'Flip',
    camera_type_not_available: 'Another camera direction is not supported on your device.',
    please_wait_for_flipping: 'The camera is flipping, please be patient.',

    // 
    step_2: 'Step 2. Select Check-in Type',
    please_select_check_in_type: 'Please select a check-in type.',

    // 
    step_3: 'Step 3. Confirm Details',
    type: 'Type',
    date: 'Date',
    check_in: 'Check-in',

    // 
    check_in_successful: 'Check-in Successful',
    back_to_homepage: 'Back to home page',

    // 
    weekday: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat'
    },

    // 
    month: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec'
    },
};

export { zh_hant, zh_hans, en };