import React from "react";
import { View, Text, StyleSheet } from 'react-native';

export const detailRowStyles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 10,
    },
    separator: {
        width: '100%',
        height: 1,
        backgroundColor: "#eee",
        marginVertical: 5
    },
    text: {
        fontSize: 17
    }
});

export const DetailRow = (props) => {
    return (
        <View>
            <View style={detailRowStyles.row}>
                <Text style={detailRowStyles.text}>{props.field}</Text>
                <Text style={detailRowStyles.text}>{props.data}</Text>
            </View>
        </View>
    );
}