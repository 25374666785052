import * as React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Text, Button, Menu, Divider, Provider } from 'react-native-paper';

import helpers from '../utils/Helper';
import Color from '../values/Color';
import Constant from '../values/Constant';

const styles = StyleSheet.create({
    langBtn: {
        position: 'absolute',
        top: 15,
        right: 15,
        backgroundColor: Color.WHITE,
        paddingVertical: 12,
        paddingHorizontal: 8,
        borderRadius: 10,
        color: Color.LIGHT_GRAY
    },
});

const LangMenu = () => {
    const [visible, setVisible] = React.useState(false);

    const openMenu = () => {
        setVisible(true);
        console.log("openMenu: " + visible);
    }

    const closeMenu = () => {
        setVisible(false);
        console.log("closeMenu: " + visible);
    }

    return (
        <Provider>
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}>
                <Menu
                    visible={visible}
                    onDismiss={closeMenu}
                    anchor={{x: 100, y: 100}, <TouchableOpacity onPress={openMenu}><Text style={styles.langBtn}>ENG</Text></TouchableOpacity>}>
                    <Menu.Item onPress={() => { }} title="Item 1" />
                    <Menu.Item onPress={() => { }} title="Item 2" />
                    <Divider />
                    <Menu.Item onPress={() => { }} title="Item 3" />
                </Menu>
            </View>
        </Provider>
    );
};

export default LangMenu;