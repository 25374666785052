import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, ActivityIndicator, TouchableOpacity } from 'react-native';
import { Camera } from 'expo-camera';
import { LinearGradient } from 'expo-linear-gradient';
import { manipulateAsync } from 'expo-image-manipulator';
import * as Permissions from 'expo-permissions';

import { useIsFocused } from '@react-navigation/native';

import Color from '../values/Color';
import Constant from '../values/Constant';
import helpers from '../utils/Helper';
import Data from '../utils/Data';

import i18n from 'i18n-js';
import { Platform } from 'expo-modules-core';

// Steps to fix the multiple hooks problem:
// https://github.com/facebook/react/issues/13991

export default function PhotoTakingScreen({ navigation, route }) {
    const [hasPermission, setHasPermission] = useState(null);
    const [type, setType] = useState(null);
    const [lang, setLang] = useState(null);
    const [canFlip, setCanFlip] = useState(null);
    const [isFlipping, setIsFlipping] = useState(false);

    // This hook returns `true` if the screen is focused, `false` otherwise
    const isFocused = useIsFocused();

    const ref = useRef(null);

    React.useEffect(() => {
        (async () => {
            await helpers.getI18nConfigs(i18n);
            setLang(i18n);
        })();

        const unsubscribe = navigation.addListener('focus', () => {
            // The screen is focused
            (async () => {
                setHasPermission(null);
                let permission = await Permissions.getAsync(Permissions.CAMERA);
                if (permission?.status !== "granted") {
                    const grantedPermission = await Permissions.askAsync(Permissions.CAMERA);
                    setHasPermission(grantedPermission?.status === "granted");
                } else {
                    setHasPermission(true);
                }
                checkIfCameraCanFlip();
            })();

            (() => {
                let camType = route.params?.type;
                if (camType != null && camType != undefined) {
                    setType(camType);
                } else {
                    setType(Camera.Constants.Type.front);
                }
                checkIfCameraCanFlip();
            })();
        });

        // Return the function to unsubscribe from the event so it gets removed on unmount
        return unsubscribe;
    }, [navigation]);

    function getReversedCameraType() {
        return (type === Camera.Constants.Type.back ? Camera.Constants.Type.front : Camera.Constants.Type.back);
    }

    async function checkIfCameraCanFlip() {
        setTimeout(async () => {
            let newType = getReversedCameraType();
            const availableTypes = await Camera.getAvailableCameraTypesAsync();
            setCanFlip(availableTypes.includes(newType));
        }, Constant.HALF_SECOND);
    }

    async function flipCamera() {
        let newType = getReversedCameraType();

        if (!isFlipping) {
            setIsFlipping(true);

            setTimeout(() => {
                let key = route.params?.key;
                if (key == Constant.REG_PHOTO) {
                    navigation.goBack();
                    navigation.navigate({
                        name: Constant.SCREEN_PHOTO,
                        params: {
                            key: key,
                            onPhotoTaken: route.params?.onPhotoTaken,
                            type: newType
                        },
                    })
                } else if (key == Constant.CHK_IN_PHOTO) {
                    navigation.goBack();
                    navigation.navigate({
                        name: Constant.SCREEN_PHOTO,
                        params: {
                            key: key,
                            chkInData: route.params?.chkInData,
                            type: newType
                        },
                    })
                } else {
                    alert(lang?.t('unknown_issue_raise'));
                    setTimeout(() => setIsFlipping(false), Constant.SECOND);
                }
            }, Constant.SECOND);
        } else {
            alert(lang?.t('please_wait_for_flipping'));
        }
    }

    async function _takePicture() {
        const options = { quality: 0.9, base64: true };
        let photoData = await ref.current.takePictureAsync(options);
        const resizedPhoto = await manipulateAsync(
            photoData.uri,
            // [{ resize: { width: 400, height: 400 } }],
            [],
            { compress: 0.6, format: 'jpeg' },
        );
        var photo = resizedPhoto.base64;
        if (!photo.includes(Constant.BASE64_PREFIX)) {
            photo = Constant.BASE64_PREFIX + photo;
        }
        await helpers.saveImageInBase64(route.params?.key, photo);

        let key = route.params?.key;
        if (photo) {
            if (key == Constant.REG_PHOTO) { // Register
                navigation.goBack();
                route.params.onPhotoTaken({ hasPhoto: true, photoUri: photo });
            } else if (key == Constant.CHK_IN_PHOTO) { // Check in
                navigation.navigate({
                    name: Constant.SCREEN_STEP_1,
                    params: { chkInData: route.params?.chkInData, photoUri: photo },
                });
            } else {
                alert(lang?.t('unknown_issue_raise'));
            }
        } else {
            alert(lang?.t('take_photo_problem'));
        }
    }

    if (hasPermission === null || isFlipping) {
        return <ActivityIndicator style={styles.centerOnScreen} size="large" color={Color.PEACH} />
    }
    if (hasPermission === false) {
        return <Text>{lang?.t('no_access_camera')}</Text>;
    }
    return (isFocused ?
        <View style={styles.container}>
            <Camera style={styles.camera}
                ref={ref} type={type}
                onCameraReady={() => {
                    // console.log("onCameraReady");
                }}
                onMountError={(error) => {
                    console.log(error);
                    alert(error);
                }}
                useCamera2Api={Platform.OS == 'android'}>
                <View style={styles.buttonRow}>
                    <TouchableOpacity style={[styles.bottomBtnStyle, styles.flipBtn, (canFlip !== true) ? { opacity: 0.5 } : {}]}
                        disabled={canFlip !== true}
                        onPress={() => {
                            flipCamera();
                        }}>
                        <Text style={[styles.bottomBtnTextStyle, styles.flipText]}>
                            {lang?.t('flip')}
                        </Text>
                    </TouchableOpacity>

                    <LinearGradient
                        colors={[Color.PEACH, Color.ORANGE]}
                        start={[0, 0]}
                        end={[1, 1]}
                        style={[styles.bottomBtnStyle, styles.takeBtn]}>
                        <TouchableOpacity
                            style={styles.gradientContent}
                            onPress={() => {
                                helpers.checkNetworkBeforeAction(() => {
                                    _takePicture();
                                })
                            }}>
                            <Text style={[styles.bottomBtnTextStyle, styles.takeText]}>
                                {lang?.t('take_picture')}
                            </Text>
                        </TouchableOpacity>
                    </LinearGradient>
                </View>
            </Camera>
        </View>

        : null
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    centerOnScreen: {
        margin: 'auto'
    },
    camera: {
        flex: 1,
    },
    buttonRow: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '90%',
        marginHorizontal: 'auto',
        paddingBottom: 20,
        backgroundColor: 'transparent',
    },
    bottomBtnStyle: {
        borderRadius: 10,
        borderWidth: 2,
        padding: 15,
        shadowColor: Color.GRAY,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 3,
    },
    bottomBtnTextStyle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginHorizontal: 'auto'
    },
    flipBtn: {
        borderWidth: 2,
        borderColor: Color.ORANGE,
        backgroundColor: Color.WHITE,
        width: '48%'
    },
    flipText: {
        color: Color.ORANGE,
    },
    takeBtn: {
        borderColor: Color.PEACH,
        backgroundColor: Color.PEACH,
        width: '48%'
    },
    takeText: {
        color: Color.WHITE,
    },
    gradientContent: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
});
