import React from "react";
import { FlatList, View, Text, StyleSheet } from 'react-native';
import { CheckInRecord } from "../components/CheckInRecord";
import Color from "../values/Color";

const styles = StyleSheet.create({
    checkInGroup: {
        width: '95%',
        marginTop: 20,
        marginHorizontal: 'auto',
        paddingHorizontal: 15,
        paddingVertical: 10,
        backgroundColor: Color.WHITE,
        borderColor: Color.WHITE,
        borderWidth: 1,
        borderRadius: 10,
        shadowColor: Color.GRAY,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 3,
    },
    separator: {
        width: '100%',
        height: 1,
        backgroundColor: "#eee",
        marginTop: 5
    },
    grayText: {
        color: Color.SEMI_GRAY,
        fontSize: 14,
    },
});

export const CheckInSection = (props) => {
    return (
        <View style={styles.checkInGroup}>
            <Text style={styles.grayText}>{props.date}</Text>

            <View style={styles.separator} />

            <FlatList
                data={props.records}
                renderItem={({ item }) =>
                    <CheckInRecord type={item.check_in_type.description}
                        date={item.check_in_datetime}
                        branch={item.branch.name}
                        imgPath={item.photo_thumb_url}
                        remarks={item.remarks}
                        lang={props.lang}
                        navigation={props.navigation} />}
                ItemSeparatorComponent={props.renderSeparator}
            />
        </View>
    );
};