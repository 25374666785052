import React from "react";
import { View, Text, Image, StyleSheet } from 'react-native';
import Color from '../values/Color';

const styles = StyleSheet.create({
    recordWrapper: {
        marginVertical: 10
    },
    textStyles: {
        fontSize: 18,
        marginBottom: 5
    },
    recordTitle: {
        color: Color.BLACK,
    },
    recordPhoto: {
        height: '100%',
        width: '22.5%',
        borderRadius: 10
    },
    recordGrayText: {
        color: Color.SEMI_GRAY,
    },
});

export const DutyRow = (props) => {
    return (
        <View style={styles.recordWrapper}>
            <Text style={[styles.textStyles, styles.recordTitle]}>{props.company}</Text>
            <Text style={[styles.textStyles, styles.recordGrayText]}>{props.store}</Text>
            <Text style={[styles.textStyles, styles.recordGrayText]}>{props.branch}</Text>
        </View>
    );
}