const Color = {
    'WHITE': "#FFFFFF",
    'BLACK': "#000000",
    'GREEN': "#5E8D48",
    'RED': '#FF0000',
    'LIGHT_GREEN': "#79B45D",
    'LIGHT_GRAY': "#C0C0C0",
    'SEMI_GRAY': "#898989",
    'LIGHT_GRAY_BG': "#f5f5f6",
    'GRAY': "#33373B",
    'PEACH': "#FB8469",
    'LIGHT_PEACH': "#FA709A",
    'ORANGE': "#F48F00",
    'LIGHT_ORANGE': "#FDA222",
    'SEMI_TRANSPARENT': 'rgba(0, 0, 0, 0.1)',
    'BLACK_TRANSPARENT': 'rgba(0, 0, 0, 0.3)',
    'TRANSPARENT': 'rgba(0, 0, 0, 0.0)'
}

export default Color;