import Color from '../values/Color';
import { StyleSheet } from 'react-native';

export const bottomBtnStyles = StyleSheet.create({
    btn: {
        width: '90%',
        position: 'absolute',
        bottom: 15,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.PEACH,
        paddingHorizontal: 8,
        paddingVertical: 12,
        borderRadius: 10
    },
    text: {
        color: Color.WHITE,
        fontSize: 16,
        fontWeight: 'bold'
    },
});