import React, { Component } from "react";
import { Image, View, Text, StyleSheet, ScrollView, StatusBar, TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import Picker from '../components/Picker';

import helpers from "../utils/Helper";
import Color from '../values/Color';
import Constant from '../values/Constant';
import Data from "../utils/Data";

import { checkInStyles } from '../styles/CheckInStyles';
import { bottomBtnStyles } from '../styles/BottomBtnStyles';
import { globalStyles } from '../styles/GlobalStyles';

import i18n from 'i18n-js';

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 10,
        backgroundColor: Color.LIGHT_GRAY_BG
    },
    picker: {
        height: '60%'
    },
    bottomNextBtn: {
        width: '90%',
        position: 'absolute',
        bottom: 15,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.PEACH,
        paddingHorizontal: 8,
        paddingVertical: 12,
        borderRadius: 10
    },
    bottomNextText: {
        color: Color.WHITE,
        fontSize: 16,
        fontWeight: 'bold'
    },
    gradientContent: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default class Step2Screen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            chkInData: this.props.route.params?.chkInData,
            hasSelected: false,
            i18n: null
        };

        this.pickerRef = React.createRef();
    }

    async componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.getCheckInTypes();
        });

        await helpers.getI18nConfigs(i18n);
        this.setState({ i18n: i18n });
    }

    getCheckInTypes = async () => {
        let types = Data.checkinTypes;
        let cloned = [];
        types.forEach((item) => {
            cloned.push(item);
            if (item.selected) this.onPress(item);
        });
        this.pickerRef.current.updateData(cloned);
    }

    onPress = (item) => {
        let chkInData = this.state.chkInData;
        chkInData.check_in_type_code = item.id;
        chkInData.check_in_type_desc = item.title;
        this.setState({ hasSelected: true });
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <StatusBar barStyle="light-content" backgroundColor={Color.LIGHT_GRAY_BG} />
                <ScrollView contentContainerStyle={styles.container} alwaysBounceVertical={false}>
                    <View style={checkInStyles.stepWhiteBox}>
                        <Image
                            style={checkInStyles.stepImg}
                            source={require('../assets/img/step2.png')} />

                        <Text style={checkInStyles.stepText}>{this.state.i18n?.t('step_2')}</Text>
                    </View>

                    <View style={[checkInStyles.stepWhiteBox, { marginBottom: 100 }]}>
                        <Picker
                            ref={this.pickerRef}
                            style={styles.picker}
                            optionStyle={[globalStyles.generalOptionStyle, globalStyles.optionStyle]}
                            selectedOptionStyle={[globalStyles.generalOptionStyle, globalStyles.selectedOptionStyle]}
                            optionTextStyle={[globalStyles.generalTextStyle, globalStyles.optionTextStyle]}
                            selectedOptionTextStyle={[globalStyles.generalTextStyle, globalStyles.selectedOptionTextStyle]}
                            data={this.state.data}
                            onPress={this.onPress}
                        />
                    </View>

                    <LinearGradient
                        colors={[Color.PEACH, Color.ORANGE]}
                        start={[0, 0]}
                        end={[1, 1]}
                        style={[bottomBtnStyles.btn]}>
                        <TouchableOpacity
                            style={styles.gradientContent}
                            onPress={() => {
                                helpers.checkNetworkBeforeAction(() => {
                                    if (this.state.hasSelected) {
                                        this.props.navigation.navigate({
                                            name: Constant.SCREEN_STEP_3,
                                            params: { chkInData: this.state.chkInData },
                                        })
                                    } else {
                                        alert(this.state.i18n?.t('please_select_check_in_type'));
                                    }
                                })
                            }}>
                            <Text style={bottomBtnStyles.text}>{this.state.i18n?.t('next')}</Text>
                        </TouchableOpacity>
                    </LinearGradient>
                </ScrollView>
            </View>
        );
    }
}