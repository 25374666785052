import React, { Component } from "react";
import { TextInput, View, Text, TouchableOpacity, StyleSheet, ScrollView, StatusBar } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import axios from 'axios';

import Color from '../values/Color';
import Constant from '../values/Constant';
import helpers from "../utils/Helper";

import i18n from 'i18n-js';

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 20,
        backgroundColor: Color.WHITE
    },
    textInput: {
        height: 50,
        width: '90%',
        padding: 10,
        fontSize: 14,
        color: Color.BLACK,
        borderColor: Color.LIGHT_GRAY,
        borderWidth: 1,
        borderRadius: 10,
        marginBottom: 15
    },
    idRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '90%',
        marginBottom: 5
    },
    idInput: {
        width: '70%',
    },
    idNumInput: {
        width: '25%',
    },
    submitBtn: {
        width: '90%',
        padding: 12,
        backgroundColor: Color.PEACH,
        marginTop: 5,
        marginBottom: 20,
        borderRadius: 5,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 20
    },
    submitText: {
        color: Color.WHITE,
        fontSize: 16,
        fontWeight: 'bold'
    },
    gradientContent: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default class ForgotPWScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            hkid: "",
            idNum: "",
            newPassword: "",
            i18n: null
        };
    }

    async componentDidMount() {
        await helpers.getI18nConfigs(i18n);
        this.setState({ i18n: i18n });
    }

    postForgotPW = async () => {
        var req = new Object();
        req.type = "hkid";
        req.phone_no = this.state.phone;
        req.hkid = this.state.hkid;
        req.hkid_checkdigit = this.state.idNum;
        req.new_password = this.state.newPassword;

        if (!req.phone_no || !req.hkid || !req.hkid_checkdigit || !req.new_password) {
            alert(this.state.i18n?.t('fill_in_required_info'));
            return;
        }

        if (!helpers.isCorrectHKID(req.hkid) || !helpers.isValidHKIDLastNum(req.hkid_checkdigit)) {
            alert(this.state.i18n?.t('invalid_hkid'));
            return;
        }

        this.setState({
            fromFetch: false,
            loading: true,
        })
        const config = await helpers.getLangHeaderOnly();
        axios.post(Constant.API_URL + Constant.API_PATH + "/staff/forgetPassword", req, config)
            .then(response => {
                this.setState({
                    loading: false,
                    axiosData: response.data
                })
                if (response.data.success) {
                    alert(this.state.i18n?.t('update_pw_successfully'));
                    this.props.navigation.goBack();
                } else {
                    alert(response.data.message);
                }
            })
            .catch(error => {
                helpers.showAPIError(error);
            });
    }

    render() {
        return (
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                <StatusBar barStyle="light-content" backgroundColor={Color.LIGHT_GRAY_BG} />
                <ScrollView contentContainerStyle={styles.container} alwaysBounceVertical={false}>
                    <TextInput
                        style={styles.textInput}
                        placeholder={this.state.i18n?.t('phone_num')}
                        placeholderTextColor={Color.LIGHT_GRAY}
                        onChangeText={(phone) => this.setState({ phone })}
                        value={this.state.phone}
                        underlineColorAndroid="transparent"
                        keyboardType="phone-pad"
                    />

                    <View style={styles.idRow}>
                        <TextInput
                            style={[styles.textInput, styles.idInput]}
                            placeholder={this.state.i18n?.t('hkid_number')}
                            placeholderTextColor={Color.LIGHT_GRAY}
                            onChangeText={(hkid) => this.setState({ hkid })}
                            value={this.state.hkid}
                            underlineColorAndroid="transparent"
                        />

                        <TextInput
                            style={[styles.textInput, styles.idNumInput]}
                            placeholder="(1)"
                            placeholderTextColor={Color.LIGHT_GRAY}
                            onChangeText={(idNum) => this.setState({ idNum })}
                            value={this.state.idNum}
                            underlineColorAndroid="transparent"
                        />
                    </View>

                    <TextInput
                        style={styles.textInput}
                        placeholder={this.state.i18n?.t('new_password')}
                        placeholderTextColor={Color.LIGHT_GRAY}
                        onChangeText={(newPassword) => this.setState({ newPassword })}
                        value={this.state.newPassword}
                        underlineColorAndroid="transparent"
                        secureTextEntry={true}
                    />

                    <LinearGradient
                        colors={[Color.PEACH, Color.ORANGE]}
                        start={[0, 0]}
                        end={[1, 1]}
                        style={styles.submitBtn}>
                        <TouchableOpacity
                            style={styles.gradientContent}
                            onPress={() => {
                                helpers.checkNetworkBeforeAction(() => {
                                    this.postForgotPW();
                                })
                            }}>
                            <Text style={styles.submitText}>
                                {this.state.i18n?.t('submit')}
                            </Text>
                        </TouchableOpacity>
                    </LinearGradient>
                </ScrollView>
            </View>
        );
    }
}