// Source: https://snyk.io/advisor/npm-package/react-native-option-picker
import React, { Component } from 'react';
import { FlatList } from 'react-native';

import PickerOption from './PickerOption';

export default class Picker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            data: this.props.data
        };
    }

    updateData(data) {
        this.setState({ data: data });
    }

    updateSelectedOption = (newSelectedOption) => {
        var onPress = this.props.onPress || null;

        this.state.data.forEach(function (element) {
            if (element.selected) {
                if (newSelectedOption.id == element.id) {
                    return false;
                } else {
                    element.selected = false;
                }
            }

            if (newSelectedOption.id == element.id) {
                element.selected = true;
            }
        });

        this.setState({ data: this.state.data });

        if (onPress) {
            onPress(newSelectedOption)
        }

        return true;
    };

    render() {
        return (
            <FlatList
                style={this.props.style}
                numColumns={1}
                data={this.state.data}
                renderItem={({ item }) => (
                    <PickerOption
                        optionStyle={this.props.optionStyle}
                        selectedOptionStyle={this.props.selectedOptionStyle}
                        optionTextStyle={this.props.optionTextStyle}
                        selectedOptionTextStyle={this.props.selectedOptionTextStyle}
                        updateSelectedOption={this.updateSelectedOption}
                        item={item}
                    />
                )}
                keyExtractor={(item) => item.id}
            />
        );
    }
}
