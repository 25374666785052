import React, { Component } from "react";
import { Image, View, Text, StyleSheet, ScrollView, StatusBar, TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import helpers from "../utils/Helper";
import Color from '../values/Color';
import Constant from '../values/Constant';

import { checkInStyles } from '../styles/CheckInStyles';
import { bottomBtnStyles } from '../styles/BottomBtnStyles';

import i18n from 'i18n-js';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Color.LIGHT_GRAY_BG
    },
    successBox: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    successImg: {
        width: 75,
        height: 75,
        resizeMode: 'contain'
    },
    successText: {
        fontSize: 18,
        marginTop: 20,
        fontWeight: 'bold'
    },
    gradientContent: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default class Step4Screen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            i18n: null
        }
    }

    async componentDidMount() {
        await helpers.getI18nConfigs(i18n);
        this.setState({ i18n: i18n });
    }

    render() {
        return (
            <View style={styles.container}>
                <StatusBar barStyle="light-content" backgroundColor={Color.LIGHT_GRAY_BG} />

                <View style={styles.successBox}>
                    <Image style={styles.successImg}
                        source={require('../assets/img/icon_tick_successful.svg')} />

                    <Text style={[checkInStyles.stepText, styles.successText]}>
                        {this.state.i18n?.t('check_in_successful')}
                    </Text>
                </View>

                <LinearGradient
                    colors={[Color.PEACH, Color.ORANGE]}
                    start={[0, 0]}
                    end={[1, 1]}
                    style={[bottomBtnStyles.btn]}>
                    <TouchableOpacity style={[styles.gradientContent]}
                        onPress={() => {
                            helpers.checkNetworkBeforeAction(() => {
                                this.props.navigation.popToTop();
                                this.props.navigation.navigate(Constant.SCREEN_HOME);
                            });
                        }}>
                        <Text style={bottomBtnStyles.text}>
                            {this.state.i18n?.t('back_to_homepage')}
                        </Text>
                    </TouchableOpacity>
                </LinearGradient>
            </View>
        );
    }
}