import React, { useLayoutEffect, useState } from 'react';
import { View, StyleSheet, Image, TouchableOpacity, Text } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import Color from './assets/src/values/Color';
import LangMenu from './assets/src/components/LangMenu';

import LoginScreen from './assets/src/screens/LoginScreen';
import RegistrationScreen from './assets/src/screens/RegistrationScreen';
import PhotoTakingScreen from './assets/src/screens/PhotoTakingScreen';
import ForgotPWScreen from './assets/src/screens/ForgotPWScreen';
import SettingsScreen from './assets/src/screens/SettingsScreen';
import LangScreen from './assets/src/screens/LangScreen';

import HomeScreen from './assets/src/screens/HomeScreen';
import ProfileScreen from './assets/src/screens/ProfileScreen';
import WebViewScreen from './assets/src/screens/WebViewScreen';
import VaccineWebScreen from './assets/src/screens/VaccineWebScreen';

import QRScreen from './assets/src/screens/QRScreen';
import Step1Screen from './assets/src/screens/Step1Screen';
import Step2Screen from './assets/src/screens/Step2Screen';
import Step3Screen from './assets/src/screens/Step3Screen';
import Step4Screen from './assets/src/screens/Step4Screen';

import Constant from './assets/src/values/Constant';

// require('dotenv').config()
// console.log(process.env.apiEndpoint);

const Stack = createNativeStackNavigator();

// RN Navigation
// https://reactnavigation.org/docs/hello-react-navigation

// Troubleshooting: 
// https://reactnavigation.org/docs/troubleshooting/
// https://docs.swmansion.com/react-native-reanimated/docs/fundamentals/installation/
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import helpers from './assets/src/utils/Helper';
import Data from './assets/src/utils/Data';

import i18n from 'i18n-js';

function LogoTitle() {
	return (
		<View style={{
			flex: 1,
			width: '100%',
			height: '100%',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
		}}>
			<Image
				style={styles.appLogo}
				source={require('./assets/src/assets/img/logo_top_bar.png')}
			/>
		</View>
	);
}

function BackButton(params) {
	return (
		<TouchableOpacity
			onPress={() => {
				helpers.checkNetworkBeforeAction(() => {
					if (!Data.isLoading) {
						if (params.goBackOnly) {
							params.navigation.goBack();
						} else {
							params.navigation.popToTop();
							if (!params.popOnly)
								params.navigation.navigate(Constant.SCREEN_HOME);
						}
					}
				})
			}}
			style={styles.backBtnWrapper}>

			<Icon style={styles.backBtnIcon} name="angle-left" size={30} color={Color.ORANGE} />
			<Text style={styles.backBtnText}>{params.lang.t('back')}</Text>

		</TouchableOpacity>
	);
}

export default function App() {
	const [lang, setLang] = useState(null);

	React.useEffect(() => {
		(async () => {
			await helpers.getI18nConfigs(i18n);
			setLang(i18n);
		})();
	});

	let customHeaderOptions = {
		headerTitle: (props) => <LogoTitle {...props} />,
		headerTitleAlign: 'center',
		headerStyle: {
			backgroundColor: Color.LIGHT_GRAY_BG,
		}
	};

	function headerWithoutLeft() {
		let opts = Object.assign({}, customHeaderOptions);
		opts.headerLeft = null;
		// opts.headerRight = () => <LangMenu />;
		return opts;
	}

	function leftBtnOptions({ navigation }, goBackOnly = false, popOnly = false, hasCamera = false) {
		let opts = Object.assign({}, customHeaderOptions);
		opts.headerLeft = () => <BackButton lang={lang} navigation={navigation}
			goBackOnly={goBackOnly} popOnly={popOnly} />;
		if (hasCamera) opts.unmountOnBlur = true;
		return opts;
	}

	const constructor = async () => {
		var lang = await helpers.getLang();

		// If not found, set 'en' as the default one.
		if (lang == null || lang == undefined || lang == "")
			lang = "zh_hant";

		await helpers.setLang(lang);
	};
	constructor();

	return (
		<NavigationContainer>
			<Stack.Navigator initialRouteName={Constant.SCREEN_LOGIN} >
				<Stack.Screen
					name={Constant.SCREEN_LOGIN}
					component={LoginScreen}
					options={{ headerShown: false }} />

				<Stack.Screen
					name={Constant.SCREEN_REGISTER}
					component={RegistrationScreen}
					options={({ navigation }) => {
						return leftBtnOptions({ navigation }, true);
					}} />

				<Stack.Screen
					name={Constant.SCREEN_PHOTO}
					component={PhotoTakingScreen}
					options={({ navigation }) => {
						return leftBtnOptions({ navigation }, true, false, true);
					}} />

				<Stack.Screen
					name={Constant.SCREEN_HOME}
					component={HomeScreen}
					options={headerWithoutLeft()} />

				<Stack.Screen
					name={Constant.SCREEN_PROFILE}
					component={ProfileScreen}
					options={({ navigation }) => {
						return leftBtnOptions({ navigation }, true);
					}} />

				<Stack.Screen
					name={Constant.SCREEN_QR}
					component={QRScreen}
					options={({ navigation }) => {
						return leftBtnOptions({ navigation }, true, false, true);
					}} />

				<Stack.Screen
					name={Constant.SCREEN_STEP_1}
					component={Step1Screen}
					options={({ navigation }) => {
						return leftBtnOptions({ navigation }, true);
					}} />

				<Stack.Screen
					name={Constant.SCREEN_STEP_2}
					component={Step2Screen}
					options={({ navigation }) => {
						return leftBtnOptions({ navigation }, true);
					}} />

				<Stack.Screen
					name={Constant.SCREEN_STEP_3}
					component={Step3Screen}
					options={({ navigation }) => {
						return leftBtnOptions({ navigation }, true);
					}} />

				<Stack.Screen
					name={Constant.SCREEN_STEP_4}
					component={Step4Screen}
					options={headerWithoutLeft()} />

				<Stack.Screen
					name={Constant.SCREEN_WEB}
					component={WebViewScreen}
					options={({ navigation }) => {
						return leftBtnOptions({ navigation }, true);
					}} />

				<Stack.Screen
					name={Constant.SCREEN_FORGOT_PW}
					component={ForgotPWScreen}
					options={({ navigation }) => {
						return leftBtnOptions({ navigation }, true);
					}} />


				<Stack.Screen
					name={Constant.SCREEN_SETTINGS}
					component={SettingsScreen}
					options={({ navigation }) => {
						return leftBtnOptions({ navigation }, true);
					}} />

				<Stack.Screen
					name={Constant.SCREEN_LANG}
					component={LangScreen}
					options={({ navigation }) => {
						return leftBtnOptions({ navigation }, true);
					}} />

				<Stack.Screen
					name={Constant.SCREEN_VACCINE}
					component={VaccineWebScreen}
					options={({ navigation }) => {
						return leftBtnOptions({ navigation }, true);
					}} />
			</Stack.Navigator>
		</NavigationContainer>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1
	},
	appLogo: {
		height: 40,
		width: 120,
		resizeMode: 'contain',
	},
	backBtnIcon: {
		paddingLeft: 12,
		marginRight: 5
	},
	backBtnText: {
		color: Color.ORANGE,
		fontSize: 18
	},
	backBtnWrapper: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	}
});
