import React, { Component } from "react";
import { Image, View, Text, StyleSheet, ScrollView, StatusBar, TouchableOpacity, ActivityIndicator } from 'react-native';
import { detailRowStyles, DetailRow } from '../components/DetailRow';
import { LinearGradient } from 'expo-linear-gradient';

import Color from '../values/Color';
import Constant from '../values/Constant';

import axios from 'axios';

import { checkInStyles } from '../styles/CheckInStyles';
import { bottomBtnStyles } from '../styles/BottomBtnStyles';
import helpers from "../utils/Helper";

import i18n from 'i18n-js';
import Data from "../utils/Data";

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 10,
        backgroundColor: Color.LIGHT_GRAY_BG
    },
    gradientContent: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    centerInParent: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    }
});

export default class Step3Screen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chkInData: this.props.route.params?.chkInData,
            loading: false,
            i18n: null
        }

        this.btnRef = React.createRef();
    }

    async componentDidMount() {
        await helpers.getI18nConfigs(i18n);
        this.setState({ i18n: i18n });
    }

    componentDidMounted() {
        this.getCurrentDate();
    }

    getCurrentDate() {
        var today = new Date();
        var date = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + today.getDate().toString().padStart(2, "0");
        var time = today.getHours().toString().padStart(2, "0") + ":" + today.getMinutes().toString().padStart(2, "0");
        return date + ' ' + time;
    }

    async getUploadUrl() {
        let imageData = await helpers.getImageDataInBase64(Constant.CHK_IN_PHOTO);
        let imageBlob = await helpers.getBlob(imageData);

        this.setState({
            fromFetch: false,
            loading: true,
        })
        Data.isLoading = true;
        axios.get(Constant.API_URL + Constant.API_PATH + "/utilities/s3_upload_url?type=checkin")
            .then(response => {
                this.setState({
                    loading: false,
                    axiosData: response.data
                })
                Data.isLoading = false;
                if (response.data.data) {
                    let data = response.data.data;
                    let signedRequest = data.signedRequest;
                    let url = data.url;
                    this.uploadAsset(signedRequest, url, imageBlob);
                } else {
                    alert(response.data.message);
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                Data.isLoading = false;
                helpers.showAPIError(error);
            });
    }

    uploadAsset(signedRequest, url, imageData) {
        this.setState({
            fromFetch: false,
            loading: true,
        })
        Data.isLoading = true;
        let config = {
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': "GET, POST, PUT, DELETE, PATCH, OPTIONS",
                'Access-Control-Allow-Headers': "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
            }
        }
        axios.put(signedRequest, imageData, config)
            .then(response => {
                this.setState({
                    loading: false,
                    axiosData: response.data
                })
                Data.isLoading = false;
                this.checkin(url);
            })
            .catch(error => {
                this.setState({ loading: false });
                Data.isLoading = false;
                helpers.showAPIError(error);
            });
    }

    async checkin(url) {
        const config = await helpers.getRequestHeader();

        let data = this.state.chkInData;
        data.photo_large_url = url;
        data.photo_thumb_url = url;

        this.setState({
            fromFetch: false,
            loading: true,
        })
        Data.isLoading = true;
        axios.post(Constant.API_URL + Constant.API_PATH + "/checkin", data, config)
            .then(response => {
                this.setState({
                    loading: false,
                    axiosData: response.data
                })
                Data.isLoading = false;

                if (!response.data.data) {
                    alert(response.data.message);
                } else {
                    this.props.navigation.navigate(Constant.SCREEN_STEP_4)
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                Data.isLoading = false;
                helpers.showAPIError(error);
            });
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <StatusBar barStyle="light-content" backgroundColor={Color.LIGHT_GRAY_BG} />
                <ScrollView contentContainerStyle={styles.container} alwaysBounceVertical={false}>
                    <View style={checkInStyles.stepWhiteBox}>
                        <Image
                            style={checkInStyles.stepImg}
                            source={require('../assets/img/step3.png')} />

                        <Text style={checkInStyles.stepText}>{this.state.i18n?.t('step_3')}</Text>
                    </View>

                    <View style={checkInStyles.stepWhiteBox}>
                        <DetailRow field={this.state.i18n?.t('type')} data={this.state.chkInData.check_in_type_desc} />
                        <View style={detailRowStyles.separator} />
                        <DetailRow field={this.state.i18n?.t('date')} data={this.getCurrentDate()} />
                    </View>

                    {this.state.loading && (
                        <ActivityIndicator style={styles.centerInParent} size="large" color={Color.PEACH} />
                    )}

                    <LinearGradient
                        colors={[Color.PEACH, Color.ORANGE]}
                        start={[0, 0]}
                        end={[1, 1]}
                        style={[bottomBtnStyles.btn]}>
                        <TouchableOpacity style={[styles.gradientContent]}
                            disabled={this.state.loading}
                            onPress={() => {
                                helpers.checkNetworkBeforeAction(() => {
                                    this.getUploadUrl();
                                })
                            }}>
                            <Text style={bottomBtnStyles.text}>{this.state.i18n?.t('check_in')}</Text>
                        </TouchableOpacity>
                    </LinearGradient>
                </ScrollView>
            </View>
        );
    }
}