import React, { Component } from "react";
import { FlatList, Image, View, Text, Button, StyleSheet, ScrollView, StatusBar, TouchableOpacity, Platform } from 'react-native';
import { CheckInRecord } from "../components/CheckInRecord";
import { CheckInSection } from "../components/CheckInSection";
import * as Location from 'expo-location';
import { LinearGradient } from 'expo-linear-gradient';

import { globalStyles } from '../styles/GlobalStyles';

import axios from 'axios';

import Color from '../values/Color';
import Constant from '../values/Constant';
import helpers from "../utils/Helper";

import i18n from 'i18n-js';

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 100,
        backgroundColor: Color.LIGHT_GRAY_BG
    },
    arrowImg: {
        width: 25,
        height: 25,
        resizeMode: 'contain'
    },
    checkInCaptionIcon: {
        width: 30,
        height: 30,
        resizeMode: 'contain',
        marginRight: 5
    },
    whiteBox: {
        width: '90%',
        paddingHorizontal: 15,
        paddingVertical: 15,
        margin: 10,
        backgroundColor: Color.WHITE,
        borderColor: Color.WHITE,
        borderWidth: 1,
        borderRadius: 10,
        shadowColor: Color.GRAY,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 3,
    },
    btnBox: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    todayBox: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    todayBoxBlock: {
        width: '50%',
    },
    grayText: {
        color: Color.SEMI_GRAY,
        fontSize: 14,
    },
    todayDateText: {
        color: Color.BLACK,
        fontSize: 20,
        fontWeight: 'bold'
    },
    checkInBtn: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.PEACH,
        paddingHorizontal: 8,
        paddingVertical: 12,
        borderRadius: 10,
    },
    checkInText: {
        color: Color.WHITE,
        fontSize: 16,
        fontWeight: 'bold'
    },
    checkInIcon: {
        marginRight: 10
    },
    checkInCaptionWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '90%',
        margin: 10,
    },
    blackText: {
        color: Color.BLACK,
        fontSize: 18,
        fontWeight: 'bold'
    },
    bottomCheckInBtn: {
        width: '90%',
    },
    gradientContent: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    timerIcon: {
        width: 20,
        height: 20,
        resizeMode: 'contain',
        marginRight: 5
    },
});

export default class HomeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recordGroups: null,
            i18n: null,
            isShowDialog: false,
            apiToken: "",
            locale: ""
        };
    }

    async updateStaffID(dutyID, shortStaffID) {
        const config = await helpers.getRequestHeader();

        this.setState({
            fromFetch: false,
            loading: true,
        });

        let duty = {};
        duty.id = dutyID;
        duty.staff_shortcode_id = shortStaffID;

        axios.post(Constant.API_URL + Constant.API_PATH + "/staff/duty/fill", duty, config)
            .then(response => {
                this.setState({
                    loading: false,
                    axiosData: response.data
                })

                let data = response.data.data;
                if (!data) {
                    alert(response.data.message);
                }
            })
            .catch(error => {
                helpers.showAPIError(error);
            });
    }

    async getDutyRecords() {
        const config = await helpers.getRequestHeader();

        this.setState({
            fromFetch: false,
            loading: true,
        })
        axios.get(Constant.API_URL + Constant.API_PATH + "/staff/duty", config)
            .then(response => {
                this.setState({
                    loading: false,
                    axiosData: response.data
                })

                let duties = response.data.data;
                if (duties) {
                    duties.forEach((item) => {
                        setTimeout(() => {
                            if (item.staff_shortcode_id == null) {
                                var shortStaffID = prompt(this.state.i18n.t('please_input_staff_id').replace('%s', item.branch.name), "");
                                if (shortStaffID != null && shortStaffID && shortStaffID !== "") {
                                    this.updateStaffID(item.id, shortStaffID);
                                }
                            }
                        }, 100);
                    });
                } else {
                    alert(response.data.message);
                }
            })
            .catch(error => {
                helpers.showAPIError(error);
            });
    }

    componentDidMounted() {
        this.getTodayDate();
        this.getDate();
    }

    async componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.getCheckInRecords();
        });

        await helpers.getI18nConfigs(i18n);
        this.setState({ i18n: i18n });

        let token = await helpers.getToken();
        this.setState({ apiToken: token });

        let lang = await helpers.getLang();
        this.setState({ locale: lang });

        this.getDutyRecords();
    }

    componentWillUnmount() {
        this.props.navigation.removeListener('focus', this._onFocus);
    }

    getTodayDate() {
        const weekdayKeys = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
        const weekdays = [];
        weekdayKeys.forEach((key) => weekdays.push(this.state.i18n?.t(['weekday.' + key])));

        const monthKeys = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
        const months = [];
        monthKeys.forEach((key) => months.push(this.state.i18n?.t(['month.' + key])));

        var today = new Date();
        var date = weekdays[today.getDay()] + ", " + months[today.getMonth()] + " " + today.getDate().toString().padStart(2, "0");
        return date;
    }

    getDate() {
        var date = new Date();
        return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + date.getDate().toString().padStart(2, "0");
    }

    goToCheckInPage() {
        helpers.checkNetworkBeforeAction(() => {
            this.props.navigation.navigate(Constant.SCREEN_QR);
        });
    }

    getCheckInRecords = async () => {
        const config = await helpers.getRequestHeader();

        this.setState({
            fromFetch: false,
            loading: true,
        })
        axios.get(Constant.API_URL + Constant.API_PATH + "/checkin/me", config)
            .then(response => {
                this.setState({
                    loading: false,
                    axiosData: response.data
                })

                let data = response.data.data;
                if (data) {
                    var group = Object.create(null), result = [];
                    data.forEach(function (o) {
                        var key = o.created_at.slice(0, 10);
                        if (!group[key]) {
                            group[key] = [];
                            result.push(group[key]);
                        }
                        group[key].push(o);
                    });
                    // console.log(result);
                    this.setState({ recordGroups: result });
                } else {
                    alert(response.data.message);
                }
            })
            .catch(error => {
                helpers.showAPIError(error);
            });
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <StatusBar barStyle="light-content" backgroundColor={Color.LIGHT_GRAY_BG} />
                <ScrollView contentContainerStyle={styles.container} alwaysBounceVertical={false}>
                    <View style={[styles.whiteBox, styles.todayBox]}>
                        <View style={styles.todayBoxBlock}>
                            <Text style={styles.grayText}>{this.state.i18n?.t('today')}</Text>
                            <Text style={styles.todayDateText}>{this.getTodayDate()}</Text>
                        </View>

                        <LinearGradient
                            colors={[Color.PEACH, Color.ORANGE]}
                            start={[0, 0]}
                            end={[1, 1]}
                            style={[styles.checkInBtn, styles.todayBoxBlock]}>
                            <TouchableOpacity
                                style={styles.gradientContent}
                                onPress={() => this.goToCheckInPage()}>
                                <Image
                                    style={styles.timerIcon}
                                    source={require('../assets/img/icon_timer.svg')}
                                />
                                <Text style={styles.checkInText}>{this.state.i18n?.t('check_in_now')}</Text>
                            </TouchableOpacity>
                        </LinearGradient>
                    </View>

                    {(() => {
                        if (this.state.recordGroups && this.state.recordGroups.length) {
                            return (
                                <View style={styles.checkInCaptionWrapper}>
                                    <Image
                                        style={styles.checkInCaptionIcon}
                                        source={require('../assets/img/icon_checkinrecords.svg')}
                                    />
                                    <Text style={styles.blackText}>{this.state.i18n?.t('check_in_records')}</Text>
                                </View>
                            )
                        }
                    })()}

                    {(() => {
                        if (this.state.recordGroups && this.state.recordGroups.length) {
                            return (
                                <FlatList
                                    style={{ width: '95%', margin: 'auto' }}
                                    data={this.state.recordGroups}
                                    renderItem={({ item }) =>
                                        <CheckInSection
                                            date={item[0].created_at.slice(0, 10)}
                                            lang={this.state.i18n}
                                            records={item}
                                            renderSeparator={this.renderSeparator}
                                            navigation={this.props.navigation} />}
                                />
                            )
                        }
                    })()}

                    {/* <TouchableOpacity
                        onPress={() => {
                            helpers.checkNetworkBeforeAction(() => {
                                this.props.navigation.navigate({
                                    name: Constant.SCREEN_VACCINE,
                                    params: { apiToken: this.state.apiToken, locale: this.state.locale },
                                });
                            })
                        }}
                        style={[styles.whiteBox, styles.btnBox]}>
                        <Text style={styles.blackText}>{this.state.i18n?.t('vaccine_record')}</Text>
                        <Image
                            style={styles.arrowImg}
                            source={require('../assets/img/icon_arrow.svg')}
                        />
                    </TouchableOpacity> */}

                    <TouchableOpacity
                        onPress={() => {
                            helpers.checkNetworkBeforeAction(() => {
                                this.props.navigation.navigate(Constant.SCREEN_PROFILE);
                            })
                        }}
                        style={[styles.whiteBox, styles.btnBox]}>
                        <Text style={styles.blackText}>{this.state.i18n?.t('my_profile')}</Text>
                        <Image
                            style={styles.arrowImg}
                            source={require('../assets/img/icon_arrow.svg')}
                        />
                    </TouchableOpacity>

                    <TouchableOpacity style={[styles.whiteBox, styles.btnBox]}
                        onPress={() => {
                            helpers.checkNetworkBeforeAction(() => {
                                this.props.navigation.navigate(Constant.SCREEN_WEB);
                            })
                        }}>
                        <Text style={styles.blackText}>{this.state.i18n?.t('tnc')}</Text>
                        <Image
                            style={styles.arrowImg}
                            source={require('../assets/img/icon_arrow.svg')}
                        />
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={() => {
                            helpers.checkNetworkBeforeAction(() => {
                                this.props.navigation.navigate({
                                    name: Constant.SCREEN_WEB,
                                    params: { link: Constant.PRIVACY_POLICY_URL },
                                });
                            })
                        }}
                        style={[styles.whiteBox, styles.btnBox]}>
                        <Text style={styles.blackText}>{this.state.i18n?.t('privacy_policy')}</Text>
                        <Image
                            style={styles.arrowImg}
                            source={require('../assets/img/icon_arrow.svg')}
                        />
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={() => {
                            helpers.checkNetworkBeforeAction(() => {
                                this.props.navigation.navigate(Constant.SCREEN_SETTINGS);
                            })
                        }}
                        style={[styles.whiteBox, styles.btnBox]}>
                        <Text style={styles.blackText}>{this.state.i18n?.t('settings')}</Text>
                        <Image
                            style={styles.arrowImg}
                            source={require('../assets/img/icon_arrow.svg')}
                        />
                    </TouchableOpacity>
                </ScrollView>

                <View style={globalStyles.fabWrapper}>
                    <LinearGradient
                        colors={[Color.PEACH, Color.ORANGE]}
                        start={[0, 0]}
                        end={[1, 1]}
                        style={[styles.checkInBtn, styles.bottomCheckInBtn]}>
                        <TouchableOpacity
                            style={styles.gradientContent}
                            onPress={() => this.goToCheckInPage()}>
                            <Image
                                style={styles.timerIcon}
                                source={require('../assets/img/icon_timer.svg')}
                            />
                            <Text style={styles.checkInText}>{this.state.i18n?.t('check_in_now')}</Text>
                        </TouchableOpacity>
                    </LinearGradient>
                </View>
            </View>
        );
    }
}