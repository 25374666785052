import Color from '../values/Color';
import { StyleSheet } from 'react-native';

export const globalStyles = StyleSheet.create({
    fabWrapper: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingBottom: 15
    },
    // Picker
    generalOptionStyle: {
        marginVertical: 5,
        width: '100%',
        height: 65,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 3,
    },
    optionStyle: {
        backgroundColor: Color.WHITE
    },
    selectedOptionStyle: {
        backgroundColor: Color.ORANGE
    },
    generalTextStyle: {
        fontWeight: 'bold',
        fontSize: 22
    },
    optionTextStyle: {
        color: Color.BLACK,
    },
    selectedOptionTextStyle: {
        color: Color.WHITE,
    },
});