import React, { Component } from "react";
import { Dimensions, FlatList, Image, View, Text, Button, StyleSheet, ScrollView, StatusBar, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

import axios from 'axios';

import Color from '../values/Color';
import Constant from '../values/Constant';
import helpers from "../utils/Helper";

import { detailRowStyles, DetailRow } from '../components/DetailRow';
import { DutyRow } from "../components/DutyRow";

import i18n from 'i18n-js';

const { height, width } = Dimensions.get('window')

function roundOff(v) {
    return Math.round(v)
}

function dimensions() {

    var _borderRadius = roundOff((height + width) / 2),
        _height = roundOff(height),
        _width = roundOff(width)

    return { _borderRadius, _height, _width }
}

const styles = StyleSheet.create({
    container: {
        width: '95%',
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginVertical: 10,
        marginHorizontal: 'auto',
        backgroundColor: Color.WHITE,
        borderColor: Color.WHITE,
        borderWidth: 1,
        borderRadius: 10,
        shadowColor: Color.GRAY,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.4,
        shadowRadius: 3,
        elevation: 5,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    profileImg: {
        padding: 20,
        backgroundColor: Color.LIGHT_GRAY,
        marginVertical: 30,
        marginHorizontal: 'auto',
        height: dimensions()._height * 0.15,
        width: dimensions()._height * 0.15,
        borderRadius: dimensions()._borderRadius,
    },
    sectionTitle: {
        color: Color.SEMI_GRAY,
        fontSize: 17
    },
    logoutBtn: {
        borderRadius: 10,
        borderWidth: 2,
        padding: 15,
        shadowColor: Color.GRAY,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 3,
        borderWidth: 2,
        borderColor: Color.ORANGE,
        backgroundColor: Color.WHITE,
        width: '90%',
        marginHorizontal: 'auto',
        marginTop: 10
    },
    logoutText: {
        color: Color.ORANGE,
        fontSize: 16,
        fontWeight: 'bold',
        marginHorizontal: 'auto'
    }
});

export default class ProfileScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: "",
            i18n: null
        };
    }

    getProfileInfo = async () => {
        const config = await helpers.getRequestHeader();

        this.setState({
            fromFetch: false,
            loading: true,
        })
        axios.get(Constant.API_URL + Constant.API_PATH + "/staff/me", config)
            .then(response => {
                this.setState({
                    loading: false,
                    axiosData: response.data
                })

                let data = response.data.data;
                if (data) {
                    this.setState({ profile: data });
                } else {
                    alert(response.data.message);
                }
            })
            .catch(error => {
                helpers.showAPIError(error);
            });
    }

    async componentDidMount() {
        this.getProfileInfo();

        await helpers.getI18nConfigs(i18n);
        this.setState({ i18n: i18n });
    }

    render() {
        return (
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                <StatusBar barStyle="light-content" backgroundColor={Color.LIGHT_GRAY_BG} />
                <ScrollView contentContainerStyle={styles.container} alwaysBounceVertical={false}>
                    <Text style={styles.title}>{this.state.i18n?.t('my_profile')}</Text>

                    <Image style={styles.profileImg}
                        source={{ uri: this.state.profile.profile_pic_thumb_url }} />

                    <Text style={styles.sectionTitle}>{this.state.i18n?.t('info')}</Text>
                    <View style={detailRowStyles.separator} />

                    <DetailRow field={this.state.i18n?.t('first_name')} data={this.state.profile.first_name} />
                    <View style={detailRowStyles.separator} />
                    <DetailRow field={this.state.i18n?.t('last_name')} data={this.state.profile.last_name} />
                    <View style={detailRowStyles.separator} />
                    <DetailRow field={this.state.i18n?.t('phone_num')} data={this.state.profile.phone_no} />
                    <View style={detailRowStyles.separator} />

                    {(() => {
                        if (this.state.profile.duties && this.state.profile.duties.length) {
                            return (
                                <Text style={[styles.sectionTitle, { marginTop: 15 }]}>{this.state.i18n?.t('duties')}</Text>
                            )
                        }
                    })()}

                    {(() => {
                        if (this.state.profile.duties && this.state.profile.duties.length) {
                            return (
                                <View style={detailRowStyles.separator} />
                            )
                        }
                    })()}

                    {(() => {
                        if (this.state.profile.duties && this.state.profile.duties.length) {
                            return (
                                <FlatList
                                    data={this.state.profile.duties}
                                    renderItem={({ item }) =>
                                        <View>
                                            <DutyRow company={item.staff_shortcode_id}
                                                store={item.company.name} branch={item.branch.name} />
                                            <View style={detailRowStyles.separator} />
                                        </View>}
                                    ItemSeparatorComponent={this.renderSeparator}
                                />
                            )
                        }
                    })()}

                    <TouchableOpacity style={styles.logoutBtn}
                        onPress={async () => {
                            await helpers.removeToken();
                            this.props.navigation.popToTop();
                        }}>
                        <Text style={styles.logoutText}>
                            {this.state.i18n?.t('logout')}
                        </Text>
                    </TouchableOpacity>
                </ScrollView>
            </View>
        );
    }
}