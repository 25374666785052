import AsyncStorage from '@react-native-async-storage/async-storage';
import Constant from '../values/Constant';
import Data from '../utils/Data';

import i18n from 'i18n-js';
import { zh_hans, zh_hant, en } from '../values/Languages';

import * as Network from 'expo-network';

const helpers = {
    isAndroidDevice() {
        var isAndroid = /(android)/i.test(navigator.userAgent);
        return isAndroid;
    },

    getLangList() {
        return [
            { id: 'zh_hant', title: '繁體中文', selected: false },
            { id: 'zh_hans', title: '简体中文', selected: false },
            { id: 'en', title: 'English', selected: false },
        ];
    },

    async checkNetworkBeforeAction(callback) {
        let pass = await helpers.isNetworkAvailable();
        let lang = await helpers.getI18nConfigs(i18n);
        if (pass) {
            callback();
        } else {
            alert(lang.t('no_network_access'));
        }
    },

    async isNetworkAvailable() {
        let network = await Network.getNetworkStateAsync();
        return network.isInternetReachable;
    },

    async getI18nConfigs(i18n) {
        i18n.fallbacks = true;
        i18n.translations = { zh_hans, zh_hant, en };
        i18n.locale = await this.getLang();
        return i18n;
    },

    async setToken(token) {
        await AsyncStorage.setItem(Constant.API_TOKEN, token);
    },

    async getToken() {
        return await AsyncStorage.getItem(Constant.API_TOKEN);
    },

    async removeToken() {
        return await AsyncStorage.removeItem(Constant.API_TOKEN);
    },

    async setLang(lang) {
        await AsyncStorage.setItem(Constant.LANG, lang);
    },

    async getLang() {
        return await AsyncStorage.getItem(Constant.LANG);
    },

    async getBlob(fileUri) {
        const resp = await fetch(fileUri);
        return await resp.blob();
    },

    async saveImageInBase64(key, data) {
        await AsyncStorage.setItem(key, data);
    },

    async getImageDataInBase64(key) {
        return await AsyncStorage.getItem(key);
    },

    async getLangHeaderOnly() {
        let lang = await this.getLang();
        return {
            headers: { "Accept-Language": lang }
        };
    },

    async getRequestHeader() {
        let token = await this.getToken();
        let lang = await this.getLang();
        return {
            headers: {
                "Accept-Language": lang,
                Authorization: `Bearer ${token}`
            }
        };
    },

    isCorrectHKID(text) {
        const regex = "^[A-Z]{1,2}\\d{6}$";
        return text.match(regex);
    },

    isValidHKIDLastNum(text) {
        const list = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A"];
        return list.includes(text);
    },

    showAPIError(error) {
        let msg = error.response.data.message;
        if (msg) {
            console.log(msg);
            alert(msg);
        } else {
            console.log(error);
            alert(error);
        }
    },
}

export default helpers;