import React, { useState } from "react";
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

import Color from '../values/Color';
import Constant from '../values/Constant';

// expo install moment
import moment from 'moment';

const styles = StyleSheet.create({
    recordWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        marginVertical: 10
    },
    recordDetails: {
        marginVertical: 10
    },
    textStyles: {
        fontSize: 16,
        marginBottom: 5
    },
    recordTitle: {
        color: Color.BLACK,
    },
    recordGrayText: {
        color: Color.SEMI_GRAY,
    },
    remarkRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    remarkText: {
        color: Color.ORANGE,
        fontSize: 14,
    },
    icon: {
        marginRight: 5
    },
});

export const CheckInRecord = (props) => {
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    const getDate = (input) => {
        const date = moment(input);
        // date.add(8, 'h');
        let d = new Date(date);
        return d.getFullYear() + '-' + String(d.getMonth() + 1).padStart(2, '0') + '-' + d.getDate().toString().padStart(2, "0") + " "
            + d.getHours().toString().padStart(2, "0") + ":" + d.getMinutes().toString().padStart(2, "0") + ":" + d.getSeconds().toString().padStart(2, "0");
    }

    React.useEffect(() => {
        if (props.imgPath) {
            Image.getSize(props.imgPath, (srcWidth, srcHeight) => {
                const maxHeight = 100;
                const maxWidth = 100;
    
                const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
                setWidth(srcWidth * ratio);
                setHeight(srcHeight * ratio);
            }, error => {
                console.log(error);
                // alert(error);
            });
        }
    }, []);

    return (
        <View style={styles.recordWrapper}>
            <View style={styles.recordDetails}>
                <Text style={[styles.textStyles, styles.recordTitle]}>{props.type}</Text>
                <Text style={[styles.textStyles, styles.recordGrayText]}>{getDate(props.date)}</Text>
                <Text style={[styles.textStyles, styles.recordGrayText]}>{props.branch}</Text>

                {
                    props.remarks != null && props.remarks != undefined ?
                        <TouchableOpacity
                            style={styles.remarkRow}
                            onPress={() => {
                                var nav = props.navigation;
                                if (nav != null && nav != undefined) {
                                    nav.navigate({
                                        name: Constant.SCREEN_WEB,
                                        params: { content: props.remarks },
                                    });
                                }
                            }}>
                            <Icon style={styles.icon} name="circle" size={10} color={Color.ORANGE} />
                            <Text style={styles.remarkText}>{props.lang.t('remarks')}</Text>
                        </TouchableOpacity>
                        :
                        null
                }
            </View>

            <Image style={{
                resizeMode: "contain",
                borderRadius: 10,
                width: width, height: height,
                marginVertical: 'auto'
            }}
                source={{ uri: props.imgPath }} />
        </View>
    );
}