import React, { Component } from "react";
import { Linking, View, Text, StyleSheet, ScrollView, StatusBar, TouchableOpacity } from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';

import Color from '../values/Color';
import Constant from '../values/Constant';
import helpers from "../utils/Helper";

import i18n from 'i18n-js';

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 10,
        backgroundColor: Color.LIGHT_GRAY_BG
    },
    whiteBox: {
        width: '90%',
        paddingVertical: 5,
        paddingHorizontal: 15,
        margin: 10,
        backgroundColor: Color.WHITE,
        borderColor: Color.WHITE,
        borderWidth: 1,
        borderRadius: 10,
        shadowColor: Color.GRAY,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 3,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 20,
    },
    separator: {
        width: '100%',
        height: 1,
        backgroundColor: "#eee",
        marginVertical: 5
    },
    text: {
        fontSize: 17
    },
    grayText: {
        color: Color.LIGHT_GRAY
    },
    arrowBtnIcon: {
        paddingLeft: 12,
    },
    emailText: {
        color: 'blue',
        textDecorationLine: 'underline'
    }
});

export default class SettingsScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            i18n: null,
        };
    }

    async componentDidMount() {
        await helpers.getI18nConfigs(i18n);
        this.setState({ i18n: i18n });
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <StatusBar barStyle="light-content" backgroundColor={Color.LIGHT_GRAY_BG} />
                <ScrollView contentContainerStyle={styles.container} alwaysBounceVertical={false}>
                    <View style={[styles.whiteBox]}>
                        <View style={styles.row}>
                            <Text style={styles.text}>{this.state.i18n?.t('language')}</Text>
                            <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center' }}
                                onPress={() => {
                                    helpers.checkNetworkBeforeAction(() => {
                                        this.props.navigation.navigate(Constant.SCREEN_LANG);
                                    })
                                }}>
                                <Text style={[styles.text, styles.grayText]}>{this.state.i18n?.t('lang_full')}</Text>
                                <Icon style={styles.arrowBtnIcon} name="angle-right" size={30} color={Color.LIGHT_GRAY} />
                            </TouchableOpacity>
                        </View>

                        {/* <View style={styles.separator} />

                        <View style={styles.row}>
                            <Text style={styles.text}>{this.state.i18n?.t('contact_us')}</Text>

                            <TouchableOpacity
                                onPress={() => {
                                    helpers.checkNetworkBeforeAction(() => {
                                        Linking.openURL('mailto:' + Constant.CS_EMAIL);
                                    })
                                }}><Text style={[styles.text, styles.emailText]}>{ Constant.CS_EMAIL }</Text>
                            </TouchableOpacity>
                        </View> */}

                        <View style={styles.separator} />

                        <View style={styles.row}>
                            <Text style={styles.text}>{this.state.i18n?.t('version_num')}</Text>
                            <Text style={styles.text}>1.0.1</Text>
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }
}