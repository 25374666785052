import React, { useState, useEffect } from 'react';
import { Text, View, StyleSheet, Button, ActivityIndicator } from 'react-native';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { Camera } from 'expo-camera';
import * as Location from 'expo-location';
import * as Permissions from 'expo-permissions';
import { useIsFocused } from '@react-navigation/native';

import axios from 'axios';

import Color from '../values/Color';
import Constant from '../values/Constant';
import helpers from "../utils/Helper";

import i18n from 'i18n-js';
import { Platform } from 'expo-modules-core';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    centerOnScreen: {
        margin: 'auto'
    },
    reminderContainer: {
        flex: 1,
        backgroundColor: Color.TRANSPARENT,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    reminderRect: {
        width: '80%',
        height: '45%',
        border: Color.WHITE,
        borderWidth: 5,
        borderStyle: 'dashed',
        backgroundColor: Color.BLACK_TRANSPARENT,
        marginBottom: 20,
        flexDirection: 'column',
        justifyContent: 'center'
    },
    redLine: {
        backgroundColor: Color.RED,
        height: 1,
        width: '100%'
    },
    reminderText: {
        fontSize: 20,
        color: Color.WHITE
    }
});

export default function QRScreen({ navigation, route }) {
    const [hasCameraPermission, setHasCameraPermission] = useState(null);
    const [hasGpsPermission, setHasGpsPermission] = useState(null);
    const [scanned, setScanned] = useState(false);
    const [location, setLocation] = useState(null);
    const [type, setType] = useState(Camera.Constants.Type.back);
    const [lang, setLang] = useState(null);

    // This hook returns `true` if the screen is focused, `false` otherwise
    const isFocused = useIsFocused();

    React.useEffect(() => {
        (async () => {
            await helpers.getI18nConfigs(i18n);
            setLang(i18n);
        })();

        const unsubscribe = navigation.addListener('focus', () => {
            (async () => {
                setScanned(false);
                setHasCameraPermission(null);
                let permission = await Permissions.getAsync(Permissions.CAMERA);
                if (permission?.status !== "granted") {
                    let grantedPermission = await Permissions.askAsync(Permissions.CAMERA);
                    setHasCameraPermission(grantedPermission?.status === "granted");
                } else {
                    setHasCameraPermission(true);
                }
            })();

            (async () => {
                try {
                    setHasGpsPermission(null);
                    setLocation(null);

                    let { status } = await Location.requestForegroundPermissionsAsync();
                    if (status != 'granted') {
                        setHasGpsPermission(false);
                        setLocation(null);
                        return;
                    }

                    let local = await Location.getCurrentPositionAsync({});
                    if (local != null && local != undefined && local.coords?.latitude && local.coords?.longitude) {
                        setLocation(local);
                        setHasGpsPermission(status === 'granted');
                    }
                } catch (ex) {
                    setHasGpsPermission(false);
                    setLocation(null);
                }
            })();
        });

        // Return the function to unsubscribe from the event so it gets removed on unmount
        return unsubscribe;
    }, [navigation]);

    const handleBarCodeScanned = ({ type, data }) => {
        helpers.checkNetworkBeforeAction(() => {
            setScanned(true);
            validateQRCode(data);
        });
    };

    const validateQRCode = async (qrcode) => {
        if (location != null) {
            const config = await helpers.getRequestHeader();

            let req = new Object();
            req.altitude = 0.0;
            req.latitude = location.coords.latitude;
            req.longitude = location.coords.longitude;
            req.qrcode = qrcode.replace(/\s/g, "+");
            // alert(req.qrcode);

            axios.post(Constant.API_URL + Constant.API_PATH + "/checkin/qrcode/validate", req, config)
                .then(response => {
                    if (!response.data.data) {
                        alert(response.data.message);
                        setScanned(false);
                    } else {
                        let data = response.data.data;
                        let chkInData = new Object();
                        chkInData.checkin_token = data.checkin_token;
                        chkInData.altitude = 0.0;
                        chkInData.latitude = req.latitude;
                        chkInData.longitude = req.longitude;

                        navigation.navigate({
                            name: Constant.SCREEN_STEP_1,
                            params: { chkInData: chkInData },
                        });
                    }
                })
                .catch(error => {
                    helpers.showAPIError(error);
                    setScanned(false);
                });
        } else {
            alert(lang?.t('obtaining_location'));
            setScanned(false);
        }
    }

    // console.log(hasCameraPermission + ", " + hasGpsPermission + ", " + location);
    if (hasCameraPermission === null || hasGpsPermission === null) {
        return <ActivityIndicator style={styles.centerOnScreen} size="large" color={Color.PEACH} />
    }
    if (hasCameraPermission === false) {
        alert(lang?.t('no_camera_access'));
        navigation.goBack();
        return null;
    }
    if (hasGpsPermission === false) {
        alert(lang?.t('no_gps_access'));
        navigation.goBack();
        return null;
    }

    return (isFocused ?
        <View style={styles.container}>
            <Camera
                type={type}
                onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
                barCodeScannerEnabled={true}
                barCodeScannerSettings={{
                    barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                }}
                onMountError={(error) => {
                    console.log(error);
                    navigation.goBack();
                    navigation.navigate(Constant.SCREEN_QR);
                    // alert(error);
                }}
                useCamera2Api={Platform.OS == 'android'}
                style={StyleSheet.absoluteFillObject}>
            </Camera>

            <View style={styles.reminderContainer}>
                <View style={styles.reminderRect}>
                    <View style={styles.redLine} />
                </View>

                <Text style={styles.reminderText}>{lang?.t('scan_qr_code_to_check_in')}</Text>
            </View>
        </View>

        : null
    );
}
