import * as React from 'react';
import { WebView } from 'react-native-webview';
import { StyleSheet, Platform, Dimensions, View } from 'react-native';

import Constant from '../values/Constant';

const deviceHeight = Dimensions.get('window').height;
const deviceWidth = Dimensions.get('window').width;

export default function VaccineWebScreen(props) {
    const link = Constant.VACCINE_RECORD_LINK + props.route.params.apiToken + "&locale=" + props.route.params.locale;

    const ref = React.useRef(null);

    function onClickLink() {
        alert("test");
    }

    function onLoadCallback(e) {
        let localRef = null;
        if (ref.current) {
            localRef = ref.current;
            const code = "document.getElementById('webFrame').contentDocument.addEventListener('click', " + onClickLink + ", false);"
            localRef.injectJavaScript(code);
        }
    }

    return (
        (Platform.OS === 'web' ?
            <iframe id="webFrame" src={link} height="100%" width="100%" onLoad={onLoadCallback} /> :

            <View style={{ flex: 1, alignItems: 'flex-end' }}>

                <WebView
                    ref={ref}
                    originWhitelist={['*']}
                    source={{ uri: link, baseUrl: '' }}
                    style={(Platform.OS != 'web' ? styles.container : null)}
                    startInLoadingState={true}
                    scalesPageToFit={true}
                    javaScriptEnabled={true}
                    domStorageEnabled={true}
                    useWebKit={true}
                />
            </View>
        )
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'yellow',
        width: deviceWidth,
        height: deviceHeight
    },
});