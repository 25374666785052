import React, { Component, useEffect } from "react";
import { Dimensions, ScrollView, Image, ActivityIndicator, Text, TouchableOpacity, StyleSheet, View, TextInput, Platform, StatusBar } from "react-native";
import { LinearGradient } from 'expo-linear-gradient';

import Color from '../values/Color';
import Constant from '../values/Constant';
import helpers from "../utils/Helper";
import Data from "../utils/Data";

import axios from 'axios';

import i18n from 'i18n-js';

const { height, width } = Dimensions.get('window')

function roundOff(v) {
    return Math.round(v)
}

function dimensions() {

    var _borderRadius = roundOff((height + width) / 2),
        _height = roundOff(height),
        _width = roundOff(width)

    return { _borderRadius, _height, _width }
}

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 10,
        backgroundColor: Color.WHITE
    },
    reminderText: {
        color: Color.PEACH,
        fontSize: 16,
        textAlign: 'center',
        margin: 10,
        marginBottom: 20
    },
    uploadImgBtn: {
        padding: 20,
        backgroundColor: Color.LIGHT_GRAY,
        marginBottom: 20,
        height: dimensions()._height * 0.2,
        width: dimensions()._height * 0.2,
        borderRadius: dimensions()._borderRadius,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 20,
        resizeMode: 'cover',
    },
    uploadImgText: {
        textAlign: 'center',
        color: Color.WHITE
    },
    textInput: {
        height: 50,
        width: '90%',
        padding: 10,
        fontSize: 14,
        color: Color.BLACK,
        borderColor: Color.LIGHT_GRAY,
        borderWidth: 1,
        borderRadius: 10,
        marginBottom: 15
    },
    idRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '90%',
        marginBottom: 5
    },
    idInput: {
        width: '70%',
    },
    idNumInput: {
        width: '25%',
    },
    tncText: {
        color: Color.BLACK,
        fontSize: 14,
        width: '90%',
        marginHorizontal: 'auto',
        marginBottom: 10
    },
    submitBtn: {
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 20,
        padding: 12,
        backgroundColor: Color.PEACH,
        marginTop: 5,
        marginBottom: 20,
        borderRadius: 5,
    },
    submitText: {
        color: Color.WHITE,
        fontSize: 16,
        fontWeight: 'bold'
    },
    gradientContent: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    centerInParent: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    }
});

export default class RegistrationScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            email: "",
            hkid: "",
            idNum: "",
            surname: "",
            firstName: "",
            password: "",
            confirmPassword: "",
            hasPhoto: false,
            photoUri: "",
            i18n: null,
            loading: false
        };
    }

    async componentDidMount() {
        await helpers.getI18nConfigs(i18n);
        this.setState({ i18n: i18n });
    }

    onPhotoTaken = (data) => {
        this.setState(data);
    }

    async validateForm() {
        var user = new Object();
        user.phone_no = this.state.phone;
        user.email = this.state.email;
        user.hkid = this.state.hkid;
        user.hkid_checkdigit = this.state.idNum;
        user.first_name = this.state.firstName;
        user.last_name = this.state.surname;
        user.password = this.state.password;

        if (!user.phone_no || !user.hkid || !user.hkid_checkdigit || !user.first_name ||
            !user.last_name || !user.password) {
            alert(this.state.i18n?.t('fill_in_required_info'));
            return;
        }

        if (this.state.password != this.state.confirmPassword) {
            alert(this.state.i18n?.t('passwords_not_match'));
            return;
        }

        if (!helpers.isCorrectHKID(user.hkid) || !helpers.isValidHKIDLastNum(user.hkid_checkdigit)) {
            alert(this.state.i18n?.t('invalid_hkid'));
            return;
        }





        this.setState({
            fromFetch: false,
            loading: true,
        });
        Data.isLoading = true;


        if (this.state.hasPhoto) {
            let imageBlob = await helpers.getBlob(this.state.photoUri);
            axios.get(Constant.API_URL + Constant.API_PATH + "/utilities/s3_upload_url?type=profile")
                .then(response => {
                    this.setState({
                        loading: false,
                        axiosData: response.data
                    });
                    Data.isLoading = false;

                    if (response.data.data) {
                        let data = response.data.data;
                        let signedRequest = data.signedRequest;
                        let url = data.url;
                        this.uploadAsset(signedRequest, url, imageBlob, user);
                    }
                })
                .catch(error => {
                    this.setState({ loading: false });
                    Data.isLoading = false;
                    helpers.showAPIError(error);
                });
        }
        else {
            this.register(user, null);
        }


    }

    uploadAsset(signedRequest, url, imageData, user) {
        this.setState({
            fromFetch: false,
            loading: true,
        });
        Data.isLoading = true;

        let config = {
            headers: {
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': "GET, POST, PUT, DELETE, PATCH, OPTIONS",
                'Access-Control-Allow-Headers': "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
            }
        }
        axios.put(signedRequest, imageData, config)
            .then(response => {
                this.setState({
                    loading: false,
                    axiosData: response.data
                });
                Data.isLoading = false;
                this.register(user, url);
            })
            .catch(error => {
                this.setState({ loading: false });
                Data.isLoading = false;
                helpers.showAPIError(error);
            });
    }

    async register(user, url = null) {

        if (url != null) {
            user.profile_pic_large_url = url;
            user.profile_pic_thumb_url = url;
        }


        this.setState({
            fromFetch: false,
            loading: true,
        });
        Data.isLoading = true;

        const config = await helpers.getLangHeaderOnly();
        axios.post(Constant.API_URL + Constant.API_PATH + "/staff/registration", user, config)
            .then(response => {
                this.setState({
                    loading: false,
                    axiosData: response.data
                });
                Data.isLoading = false;

                if (!response.data.data) {
                    alert(response.data.message);
                } else {
                    alert(this.state.i18n?.t('registered_successfully'));

                    // Change screen
                    this.props.navigation.popToTop()
                }
            })
            .catch(error => {
                this.setState({ loading: false });
                Data.isLoading = false;
                helpers.showAPIError(error);
            });
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <StatusBar barStyle="light-content" backgroundColor={Color.LIGHT_GRAY_BG} />
                <ScrollView contentContainerStyle={styles.container} alwaysBounceVertical={false}>
                    {this.state.loading && (
                        <ActivityIndicator style={styles.centerInParent} size="large" color={Color.PEACH} />
                    )}

                    <Text style={styles.reminderText}>{this.state.i18n?.t('register_reminder')}</Text>

                    {(() => {
                        if (!this.state.hasPhoto) {
                            return (
                                <TouchableOpacity
                                    style={styles.uploadImgBtn}
                                    onPress={() => {
                                        helpers.checkNetworkBeforeAction(() => {
                                            this.props.navigation.navigate({
                                                name: Constant.SCREEN_PHOTO,
                                                params: { key: Constant.REG_PHOTO, onPhotoTaken: this.onPhotoTaken },
                                            })
                                        })
                                    }}>

                                    <Text style={styles.uploadImgText}>{this.state.i18n?.t('upload_photo')}</Text>

                                </TouchableOpacity>
                            )
                        } else {
                            return (
                                <TouchableOpacity
                                    disabled={this.state.loading}
                                    onPress={() =>
                                        this.props.navigation.navigate({
                                            name: Constant.SCREEN_PHOTO,
                                            params: { key: Constant.REG_PHOTO, onPhotoTaken: this.onPhotoTaken },
                                        })
                                    }>
                                    <Image
                                        style={styles.uploadImgBtn}
                                        source={{ uri: this.state.photoUri }} />
                                </TouchableOpacity>
                            )
                        }
                    })()}

                    <TextInput
                        style={styles.textInput}
                        placeholder={this.state.i18n?.t('phone_number_reg')}
                        placeholderTextColor={Color.LIGHT_GRAY}
                        onChangeText={(phone) => this.setState({ phone })}
                        value={this.state.phone}
                        underlineColorAndroid="transparent"
                        keyboardType="phone-pad"
                        editable={!this.state.loading}
                        selectTextOnFocus={!this.state.loading}
                    />

                    <TextInput
                        style={styles.textInput}
                        placeholder={this.state.i18n?.t('email')}
                        placeholderTextColor={Color.LIGHT_GRAY}
                        onChangeText={(email) => this.setState({ email })}
                        value={this.state.email}
                        underlineColorAndroid="transparent"
                        keyboardType="email-address"
                        editable={!this.state.loading}
                        selectTextOnFocus={!this.state.loading}
                    />

                    <View style={styles.idRow}>
                        <TextInput
                            style={[styles.textInput, styles.idInput]}
                            placeholder={this.state.i18n?.t('hkid_number')}
                            placeholderTextColor={Color.LIGHT_GRAY}
                            onChangeText={(hkid) => this.setState({ hkid })}
                            value={this.state.hkid}
                            underlineColorAndroid="transparent"
                            editable={!this.state.loading}
                            selectTextOnFocus={!this.state.loading}
                        />

                        <TextInput
                            style={[styles.textInput, styles.idNumInput]}
                            placeholder="(1)"
                            placeholderTextColor={Color.LIGHT_GRAY}
                            onChangeText={(idNum) => this.setState({ idNum })}
                            value={this.state.idNum}
                            underlineColorAndroid="transparent"
                            editable={!this.state.loading}
                            selectTextOnFocus={!this.state.loading}
                        />
                    </View>

                    <TextInput
                        style={styles.textInput}
                        placeholder={this.state.i18n?.t('surname_last_name')}
                        placeholderTextColor={Color.LIGHT_GRAY}
                        onChangeText={(surname) => this.setState({ surname })}
                        value={this.state.surname}
                        underlineColorAndroid="transparent"
                        editable={!this.state.loading}
                        selectTextOnFocus={!this.state.loading}
                    />

                    <TextInput
                        style={styles.textInput}
                        placeholder={this.state.i18n?.t('other_name_first_name')}
                        placeholderTextColor={Color.LIGHT_GRAY}
                        onChangeText={(firstName) => this.setState({ firstName })}
                        value={this.state.firstName}
                        underlineColorAndroid="transparent"
                        editable={!this.state.loading}
                        selectTextOnFocus={!this.state.loading}
                    />

                    <TextInput
                        style={styles.textInput}
                        placeholder={this.state.i18n?.t('password')}
                        placeholderTextColor={Color.LIGHT_GRAY}
                        onChangeText={(password) => this.setState({ password })}
                        value={this.state.password}
                        underlineColorAndroid="transparent"
                        secureTextEntry={true}
                        editable={!this.state.loading}
                        selectTextOnFocus={!this.state.loading}
                    />

                    <TextInput
                        style={styles.textInput}
                        placeholder={this.state.i18n?.t('confirm_password')}
                        placeholderTextColor={Color.LIGHT_GRAY}
                        onChangeText={(confirmPassword) => this.setState({ confirmPassword })}
                        value={this.state.confirmPassword}
                        underlineColorAndroid="transparent"
                        secureTextEntry={true}
                        editable={!this.state.loading}
                        selectTextOnFocus={!this.state.loading}
                    />

                    <Text style={styles.tncText}>{this.state.i18n?.t('terms_and_conditions_reg')}</Text>

                    <LinearGradient
                        colors={[Color.PEACH, Color.ORANGE]}
                        start={[0, 0]}
                        end={[1, 1]}
                        style={styles.submitBtn}>
                        <TouchableOpacity
                            style={styles.gradientContent}
                            disabled={this.state.loading}
                            onPress={() => {
                                helpers.checkNetworkBeforeAction(() => {
                                    this.validateForm();
                                })
                            }}>
                            <Text style={styles.submitText}>{this.state.i18n?.t('sign_up')}</Text>
                        </TouchableOpacity>
                    </LinearGradient>
                </ScrollView>
            </View>
        );
    }
}