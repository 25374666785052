import React, { Component } from "react";
import { FlatList, Image, View, Text, Button, StyleSheet, ScrollView, StatusBar, TouchableOpacity } from 'react-native';

import Picker from '../components/Picker';

import Color from '../values/Color';
import Constant from '../values/Constant';
import helpers from "../utils/Helper";

import { globalStyles } from '../styles/GlobalStyles';
import { checkInStyles } from '../styles/CheckInStyles';

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 10,
        backgroundColor: Color.LIGHT_GRAY_BG
    },
});

export default class LangScreen extends Component {
    constructor(props) {
        super(props);
        this.pickerRef = React.createRef();
        this.state = {
            data: helpers.getLangList(),
        }
    }

    async componentDidMount() {
        let lang = await helpers.getLang();
        let newList = [...this.state.data];
        this.state.data.forEach((item, index) => {
            if (item.id == lang) {
                item.selected = true;
                newList[index] = item;
            }
        });
        this.setState({ data: newList });
    }

    async onPress(item) {
        await helpers.setLang(item.id);
        location.reload();
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <StatusBar barStyle="light-content" backgroundColor={Color.LIGHT_GRAY_BG} />
                <ScrollView contentContainerStyle={styles.container} alwaysBounceVertical={false}>
                    <View style={checkInStyles.stepWhiteBox}>
                        <Picker
                            ref={this.pickerRef}
                            optionStyle={[globalStyles.generalOptionStyle, globalStyles.optionStyle]}
                            selectedOptionStyle={[globalStyles.generalOptionStyle, globalStyles.selectedOptionStyle]}
                            optionTextStyle={[globalStyles.generalTextStyle, globalStyles.optionTextStyle]}
                            selectedOptionTextStyle={[globalStyles.generalTextStyle, globalStyles.selectedOptionTextStyle]}
                            data={this.state.data}
                            onPress={this.onPress}
                        />
                    </View>
                </ScrollView>
            </View>
        )
    }
}