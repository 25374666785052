import Color from '../values/Color';
import { StyleSheet } from 'react-native';

export const checkInStyles = StyleSheet.create({
    stepWhiteBox: {
        width: '90%',
        padding: 20,
        margin: 10,
        backgroundColor: Color.WHITE,
        borderColor: Color.WHITE,
        borderWidth: 1,
        borderRadius: 10,
        shadowColor: Color.GRAY,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 3,
    },
    stepImg: {
        width: '100%',
        height: 50,
        resizeMode: 'contain',
    },
    stepText: {
        color: Color.ORANGE,
        fontSize: 16,
        marginHorizontal: 'auto',
        marginTop: 10
    }, 
});