import React, { Component } from "react";
import { Image, View, Text, StyleSheet, ScrollView, StatusBar, TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import Color from '../values/Color';
import Constant from '../values/Constant';
import { checkInStyles } from '../styles/CheckInStyles';
import helpers from "../utils/Helper";

import i18n from 'i18n-js';

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 10,
        backgroundColor: Color.LIGHT_GRAY_BG
    },
    takenPic: {
        width: '80%',
        height: '50%',
        aspectRatio: 1,
        resizeMode: 'contain',
        margin: 30,
        marginBottom: 100,
        borderRadius: 20
    },
    bottomBtnWrapper: {
        position: 'absolute',
        bottom: 20,
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    bottomBtnStyle: {
        borderRadius: 10,
        borderWidth: 2,
        padding: 15,
        shadowColor: Color.GRAY,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 3,
    },
    bottomBtnTextStyle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginHorizontal: 'auto'
    },
    retakeBtn: {
        borderWidth: 2,
        borderColor: Color.ORANGE,
        backgroundColor: Color.WHITE,
        width: '48%'
    },
    halfBtn: {
        width: '48%'
    },
    fullBtn: {
        width: '100%'
    },
    retakeText: {
        color: Color.ORANGE,
    },
    nextBtn: {
        borderColor: Color.PEACH,
        backgroundColor: Color.PEACH,
    },
    nextText: {
        color: Color.WHITE,
    },
    gradientContent: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default class Step1Screen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoUri: "",
            i18n: null
        }
    }

    async componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.loadImage();
        });

        await helpers.getI18nConfigs(i18n);
        this.setState({ i18n: i18n });
    }

    componentWillUnmount() {
        this.props.navigation.removeListener('focus', this._onFocus);
    }

    loadImage() {
        this.setState({ photoUri: this.props.route.params?.photoUri });
    }

    hasPhoto() {
        return (typeof this.state.photoUri !== 'undefined' && this.state.photoUri !== null && this.state.photoUri != "");
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <StatusBar barStyle="light-content" backgroundColor={Color.LIGHT_GRAY_BG} />
                <ScrollView contentContainerStyle={styles.container} alwaysBounceVertical={false}>
                    <View style={checkInStyles.stepWhiteBox}>
                        <Image
                            style={checkInStyles.stepImg}
                            source={require('../assets/img/step1.png')} />

                        <Text style={checkInStyles.stepText}>{this.state.i18n?.t('step_1')}</Text>
                    </View>

                    <Image style={styles.takenPic}
                        visible={(this.hasPhoto() == true)}
                        source={{ uri: this.state.photoUri }} />

                    <View style={styles.bottomBtnWrapper}>
                        {(() => {
                            if (this.hasPhoto()) {
                                return (
                                    <TouchableOpacity style={[styles.bottomBtnStyle, styles.retakeBtn]}
                                        onPress={() => {
                                            helpers.checkNetworkBeforeAction(() => {
                                                this.props.navigation.navigate({
                                                    name: Constant.SCREEN_PHOTO,
                                                    params: { key: Constant.CHK_IN_PHOTO, chkInData: this.props.route.params?.chkInData },
                                                })
                                            })
                                        }}>
                                        <Text style={[styles.bottomBtnTextStyle, styles.retakeText]}>
                                            {this.state.i18n?.t('retake_photo')}
                                        </Text>
                                    </TouchableOpacity>
                                )
                            }
                        })()}

                        <LinearGradient
                            colors={[Color.PEACH, Color.ORANGE]}
                            start={[0, 0]}
                            end={[1, 1]}
                            style={[styles.bottomBtnStyle, styles.nextBtn, this.hasPhoto() ? styles.halfBtn : styles.fullBtn]}>
                            <TouchableOpacity
                                style={styles.gradientContent}
                                onPress={() => {
                                    helpers.checkNetworkBeforeAction(() => {
                                        if (this.hasPhoto()) {
                                            this.props.navigation.navigate({
                                                name: Constant.SCREEN_STEP_2,
                                                params: { chkInData: this.props.route.params?.chkInData },
                                            })
                                        } else {
                                            this.props.navigation.navigate({
                                                name: Constant.SCREEN_PHOTO,
                                                params: { key: Constant.CHK_IN_PHOTO, chkInData: this.props.route.params?.chkInData },
                                            })
                                        }
                                    })
                                }}>
                                <Text style={[styles.bottomBtnTextStyle, styles.nextText]}>
                                    {this.state.i18n?.t('next')}
                                </Text>
                            </TouchableOpacity>
                        </LinearGradient>
                    </View>
                </ScrollView>
            </View>
        );
    }
}